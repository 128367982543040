import { lazy } from "react";

export default [
	{
		path: "/borrower/create",
		Component: lazy(() => import("./../../borrower/create_borrower/borrower_create.js")),
		permission: ["add borrower"],
	},
	{
		path: "/borrower/view",
		Component: lazy(() => import("./../../borrower/view_borrower.js")),
		permission: ["add borrower", "view borrower"],
	},
	{
		path: "/borrower/edit_working_status/:id",
		Component: lazy(() =>
			import("./../../borrower/edit_working_status/edit_working_status.js")
		),
		permission: ["add borrower", "view borrower"],
	},
	{
		path: "/borrower/edit_borrower_details/:id",
		Component: lazy(() => import("./../../borrower/edit_borrower_details/edit_borrower.js")),
		permission: ["add borrower", "view borrower"],
	},
	{
		path: "/borrower/group/view",
		Component: lazy(() => import("./../../borrower/borrower_group/view_group.js")),
		permission: [],
	},
	{
		path: "/borrower/group/:action",
		Component: lazy(() => import("./../../borrower/borrower_group/add_group.js")),
		permission: [],
	},
	{
		path: "/borrower/send_sms",
		Component: lazy(() => import("./../../borrower/invite/send_sms.js")),
		permission: [],
	},
	{
		path: "/borrower/invite_customer",
		Component: lazy(() => import("./../../borrower/invite/invite_customer.js")),
		permission: [],
	},
	{
		path: "/borrower/send_email",
		Component: lazy(() => import("./../../borrower/invite/send_email.js")),
		permission: [],
	},
];
