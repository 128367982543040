import { useState } from "react";
import { validateLoginFormData } from "../form-validation/login-validation";
import { login } from "../utils/api-request";
import { ExtractUserContext } from "../context/app_context";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { extractToken, setTokenToStorage } from "../utils/functions";

export const useLogin = () => {
	const { addToast } = useToasts();

	const history = useHistory();

	const { setUserObject } = ExtractUserContext();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [errors, setErrors] = useState({});

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const handleFormSubmit = async () => {
		setIsSubmitting(true);

		const { validationErrors, noOfError } = validateLoginFormData(formData);
		console.log(validationErrors, noOfError);

		setErrors(validationErrors);

		if (noOfError > 0) {
			setIsSubmitting(false);
			return;
		}
		const userData = { ...formData };

		try {
			const response = await login(userData);

			if (response.status === 200) {
				addToast("Login Successfull", {
					appearance: "success",
					autoDismiss: true,
				});
				setIsSubmitting(false);
				setUserObject(response.data.data);
				const token = extractToken(response.data.data.token);
				setTokenToStorage(token);
				history.push("/dashboard");

				// console.log(response.data.data, token);

				// history.push("/login");
			}
		} catch (error) {
			setIsSubmitting(false);

			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response.status === 401) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			} else {
				addToast("Something went wrong", {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return {
		formData,
		isSubmitting,
		errors,
		setUserObject,
		handleInputChange,
		handleFormSubmit,
	};
};
