import React from "react";

const getInitials = (first, last) => {
	const init = first.slice(0, 1).toUpperCase();
	const init2 = last.slice(0, 1).toUpperCase();
	return `${init}${init2}`;
};

const TableRowRepaymentCustomer = ({ children, data, ...props }) => {
	const {
		amount,
		collection_type,
		interest_amount,
		liability_amount,
		outstanding_principal_amount,
		principal_amount,
	} = data;

	return (
		<tr className="text-gray-600 pr-4 pl-6 shadow-md text-sm">
			<td>
				<div className="bg-pink-200 text-white rounded-full w-10 h-10 flex justify-center items-center">
					<p className="text-purple-700">
						{/* {getInitials(user.first_name, user.last_name)} */}
					</p>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Collection Type</p>
					<span className="small-font3 font-bold">{collection_type}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Amount</p>
					<span className="small-font3 font-bold">{amount}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Interest</p>
					<span className="small-font3 font-bold">{interest_amount}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Liability</p>
					<span className="small-font3 font-bold">{liability_amount}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Oustanding</p>
					<span className="small-font3 font-bold">{outstanding_principal_amount}</span>
				</div>
			</td>

			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Principal</p>
					<span className="small-font3 font-bold">{principal_amount}</span>
				</div>
			</td>

			{children}

			{/* <TdLastChildLoan
            handleSideData={handleSideData}
            refetchData={refetchData}
            toggleLoader={toggleLoader}
            data={data}
        /> */}
		</tr>
	);
};

export default TableRowRepaymentCustomer;
