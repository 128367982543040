import React, { useEffect, useState } from "react";
import { Modal } from "../../../components/modal";

const formatTime = (time) => {
	console.log(time);
	if (time === undefined) {
		return;
	}
	if (time === null) {
		return "Not Available";
	}
	let newDate = time.split("T")[0].split("-");
	return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
};

const CurrentRepaymentModal = ({ dispatch, data }) => {
	// const [loading, setLoading] = useState(false);

	const [currentData, setCurrentData] = useState({});

	const computedCurrentPayment =
		currentData.current_payment > 0 ? `₦${currentData.current_payment}` : "PAID";

	const closeModal = () => {
		dispatch({ type: "CHANGE", payload: { currentRepaymentModal: false } });
	};

	useEffect(() => {
		const newData = {
			current_principal: data.current_period_principal,
			current_interest: data.current_period_interest,
			current_liability: data.current_liability,
			start_date: data.start_date,
			end_date: data.end_date,
			next_principal: data.outstanding_principal,
			current_payment: data.total_period_payment,
		};
		setCurrentData({ ...newData });
		// setId(data.id);
	}, [data]);

	return (
		<Modal close={closeModal} loading={false}>
			<div className="p-5 bg-white shadow-2xl rounded">
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}
					className="w-96 mt-5"
				>
					<div className="mb-6">
						<div className="mb-0">
							<h6 className="text-gray-600 mb-1">Due Repayment</h6>
							<p className="text-gray-500">{computedCurrentPayment}</p>
						</div>
					</div>

					<div className="grid grid-cols-2 gap-6">
						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">Due Principal</h6>
							<p className="text-gray-500">
								₦{parseInt(currentData.current_principal).toLocaleString()}
							</p>
						</div>
						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">Due Interest</h6>
							<p className="text-gray-500">
								₦{parseInt(currentData.current_interest).toLocaleString()}
							</p>
						</div>

						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">Start Date</h6>
							<p className="text-gray-500">{formatTime(currentData.start_date)}</p>
						</div>
						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">End Date</h6>
							<p className="text-gray-500">{formatTime(currentData.end_date)}</p>
						</div>
						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">Outstanding Principal</h6>
							<p className="text-gray-500">
								₦{parseInt(currentData.next_principal).toLocaleString()}
							</p>
						</div>

						<div className="mb-3">
							<h6 className="text-gray-600 mb-1">Current Liability</h6>
							<p className="text-gray-500">
								₦{parseInt(currentData.current_liability).toLocaleString()}
							</p>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default CurrentRepaymentModal;
