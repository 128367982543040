import { lazy } from 'react';
import { permissionsAllowed } from '../../../utils/permissions.js';

export default [
  {
    path: '/other-incomes/view',
    Component: lazy(() =>
      import('./../../other_incomes/view_other_incomes.js')
    ),
    permission: [],
  },
  {
    path: '/other-incomes/add-other-incomes',
    Component: lazy(() => import('./../../other_incomes/AddOtherIncomes.js')),
    permission: [],
  },
  {
    path: '/other-incomes/other-incomes-types',
    Component: lazy(() => import('./../../other_incomes/OtherIncomesTypes.js')),
    permission: [],
  },
  {
    path: '/other-incomes/add-other-incomes-types',
    Component: lazy(() =>
      import('./../../other_incomes/AddOtherIncomesTypes.js')
    ),
    permission: [],
  },
  //   {
  //     path: '/accounting/cash-flow-monthly',
  //     Component: lazy(() => import('./../../accounting/CashFlowMonthly.js')),
  //     permission: [],
  //   },
  //   {
  //     path: '/accounting/profit-loss',
  //     Component: lazy(() => import('./../../accounting/ProfitLoss.js')),
  //     permission: [],
  //   },
  //   {
  //     path: '/accounting/balance-sheet',
  //     Component: lazy(() => import('./../../accounting/BalanceSheet.js')),
  //     permission: [],
  //   },
  // {
  // 	path: "/user/view",
  // 	Component: lazy(() => import("./../../user/view_users.js")),
  // 	permission: [
  // 		permissionsAllowed.view_customer,
  // 		permissionsAllowed.add_customer,
  // 	],
  // },
];
