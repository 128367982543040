import React from "react";

const DashboardCard = ({ value, label }) => {
	return (
		<article className="p-4 flex rounded-sm shadow bg-white">
			<img
				className="w-12 mr-5"
				src={require("../../../assets/wallett.svg")}
				alt=""
			/>
			<div className="self-start">
				<h3 className="font-normal">₦{value}</h3>
				<span className="small-font3 cusCol">{label}</span>
			</div>
		</article>
	);
};

export default DashboardCard;
