import React, { useEffect, useState } from "react";
import { ExtractUserContext } from "../context/app_context";
import { getACustomer, updateCustomerDetails } from "../utils/api-request";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

export const useManageProfile = () => {
	const { appState } = ExtractUserContext();
	const [formData, setFormData] = useState({});
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const { addToast } = useToasts();

	const history = useHistory();

	const handleChange = ({ target }) => {
		setFormData({ ...formData, [target.name]: target.value });
	};

	const handleUpdate = async (e) => {
		e.preventDefault();
		const { validationErrors, errorNo } = validation(formData);
		setErrors(validationErrors);
		if (errorNo > 0) {
			return;
		}
		setLoading(true);

		const userData = formData;

		try {
			const response = await updateCustomerDetails(userData);
			if (response.status === 200) {
				addToast("Profile updated successfully", {
					appearance: "success",
					autoDismiss: true,
				});
				setLoading(false);
			}
			history.push("/customer");
			// console.log(response);
		} catch (error) {
			setLoading(false);
			if (!error.response) {
				addToast("Kindly check your network and try again", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response.status === 404) {
				addToast("Customer does not exists", {
					appearance: "error",
					autoDismiss: true,
				});
			} else {
				console.log(error.response);
			}
		}
	};

	const validation = (values) => {
		let validationErrors = {};
		let errorNo = 0;

		if (!values.first_name) {
			validationErrors.first_name = "First name is required";
			errorNo += 1;
		}

		if (!values.last_name) {
			validationErrors.last_name = "Last name is required";
			errorNo += 1;
		}

		if (!values.phone) {
			validationErrors.phone = "Phone number is required";
			errorNo += 1;
		} else if (values.phone && values.phone.length < 11) {
			validationErrors.phone = "Please enter a valid phone number";
			errorNo += 1;
		}

		if (!values.username) {
			validationErrors.username = "Please enter a username";
			errorNo += 1;
		}

		if (!values.email) {
			validationErrors.email = "Email address is required";
			errorNo += 1;
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			validationErrors.email = "Please enter a valid email address";
			errorNo += 1;
		}

		return { validationErrors, errorNo };
	};

	useEffect(() => {
		setLoading(true);
		const id = appState.user.customer_id;

		const getDetails = async (id) => {
			try {
				const response = await getACustomer(id);
				const result = response.data.data;
				setFormData({
					...formData,
					first_name: result.first_name,
					last_name: result.last_name,
					email: result.email,
					phone: result.phone,
					username: result.username,
				});
				setLoading(false);
			} catch (error) {
				setLoading(false);
				if (!error.response) {
					addToast("Kindly check your network and try again", {
						appearance: "error",
						autoDismiss: true,
					});
				} else {
					console.log(error.response);
				}
			}
		};
		getDetails(id);
	}, []);

	return {
		formData,
		loading,
		errors,
		handleChange,
		handleUpdate,
	};
};
