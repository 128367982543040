import React from "react";
import Chart from "react-apexcharts";

const PieChart = ({ series, pieWidth }) => {
	const labelData = series.map((each) => {
		return each.product;
	});

	const seriesData = series.map((each) => {
		return each.pie;
	});
	const settings = {
		// colors: [`${colorValue}`],
		// colors: ["#6F9BDC"],
		// labels: [amount],
		legend: {
			show: true,
			showForSingleSeries: false,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: "bottom",
			horizontalAlign: "center",
			floating: false,
			fontSize: "8px",
			fontFamily: "Helvetica, Arial",
			fontWeight: 400,
			formatter: undefined,
			inverseOrder: false,
			width: undefined,
			height: undefined,
			tooltipHoverFormatter: undefined,
			offsetX: 0,
			offsetY: 0,
			labels: {
				colors: undefined,
				useSeriesColors: false,
			},
			markers: {
				width: 7,
				height: 7,
				strokeWidth: 0,
				strokeColor: "#fff",
				fillColors: undefined,
				radius: 7,
				customHTML: undefined,
				onClick: undefined,
				offsetX: 0,
				offsetY: 0,
			},
			itemMargin: {
				horizontal: 5,
				vertical: 0,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
		dataLabels: {
			dropShadow: {
				enabled: true,
				top: 1,
				left: 1,
				blur: 3,
				color: "#000",
				opacity: 0.01,
			},
		},
		labels: labelData,
		plotOptions: {
			pie: {
				startAngle: 0,
				endAngle: 360,
				expandOnClick: true,
				offsetX: 0,
				offsetY: 0,
				customScale: 1,
				dataLabels: {
					offset: 0,
					minAngleToShowLabel: 10,
				},
				donut: {
					size: "50%",

					background: "transparent",
					labels: {
						show: false,
						name: {
							show: true,
							fontSize: "22px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 600,
							color: undefined,
							offsetY: -10,
							formatter: function (val) {
								return val;
							},
						},
						value: {
							show: false,
							fontSize: "16px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 400,
							color: undefined,
							offsetY: 16,
							formatter: function (val) {
								return val;
							},
						},
						total: {
							show: false,
							showAlways: false,
							label: "Total",
							fontSize: "22px",
							fontFamily: "Helvetica, Arial, sans-serif",
							fontWeight: 600,
							color: "#373d3f",
							formatter: function (w) {
								return w.globals.seriesTotals.reduce((a, b) => {
									return a + b;
								}, 0);
							},
						},
					},
				},
			},
		},
	};

	return (
		<div>
			<Chart options={settings} series={seriesData} type="donut" width={pieWidth} />
		</div>
	);
};

export default PieChart;
