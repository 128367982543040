import { lazy } from "react";

export default [
	{
		path: "/report/borrower",
		Component: lazy(() => import("./../../report/borrower_report.js")),
		permission: [],
	},
	{
		path: "/report/loan_report",
		Component: lazy(() => import("./../../report/loan_report.js")),
		permission: [],
	},
	{
		path: "/report/collection_report",
		Component: lazy(() => import("./../../report/collection_report.js")),
		permission: [],
	},
	{
		path: "/report/loan_officer_report",
		Component: lazy(() => import("./../../report/loan_officer_report.js")),
		permission: [],
	},
	{
		path: "/report/loan_product_report",
		Component: lazy(() => import("./../../report/loan_product_report.js")),
		permission: [],
	},
	{
		path: "/report/at_a_glance_report",
		Component: lazy(() => import("./../../report/at_a_glance_report.js")),
		permission: [],
	},
	{
		path: "/report/all_entries_report",
		Component: lazy(() => import("./../../report/all_entries_report.js")),
		permission: [],
	},
	{
		path: "/report/disbursement_report",
		Component: lazy(() => import("./../../report/disbursement_report.js")),
		permission: [],
	},
	{
		path: "/report/outstanding_report",
		Component: lazy(() => import("./../../report/outstanding_report.js")),
		permission: [],
	},
	{
		path: "/report/portfolio_at_risk_report",
		Component: lazy(() =>
			import("./../../report/portfolio_at_risk_report.js")
		),
		permission: [],
	},
	{
		path: "/report/fees_report",
		Component: lazy(() => import("./../../report/fees_report.js")),
		permission: [],
	},
	{
		path: "/report/collections_by_staff_report",
		Component: lazy(() =>
			import("./../../report/collections_by_staff_report.js")
		),
		permission: [],
	},
	{
		path: "/report/monthly_report",
		Component: lazy(() => import("./../../report/monthly_report.js")),
		permission: [],
	},
	{
		path: "/report/loan_arrears_aging_report",
		Component: lazy(() =>
			import("./../../report/loan_arrears_aging_report.js")
		),
		permission: [],
	},
	{
		path: "/report/deferred_monthly_income",
		Component: lazy(() =>
			import("./../../report/deferred_monthly_income.js")
		),
		permission: [],
	},
];
