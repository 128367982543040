import { lazy } from 'react';
import { permissionsAllowed } from '../../../utils/permissions.js';

export default [
  {
    path: '/asset-management/view',
    Component: lazy(() =>
      import('./../../asset-management/ViewAssetManagement.js')
    ),
    permission: [],
  },
  {
    path: '/asset-management/add',
    Component: lazy(() =>
      import('./../../asset-management/AddAssetManagement.js')
    ),
    permission: [],
  },
  {
    path: '/asset-management/type',
    Component: lazy(() =>
      import('./../../asset-management/AssetManagementType.js')
    ),
    permission: [],
  },
  {
    path: '/asset-management/add-type',
    Component: lazy(() =>
      import('./../../asset-management/AddAssetManagementType.js')
    ),
    permission: [],
  },
  {
    path: '/asset-management/asset-management',
    Component: lazy(() =>
      import('./../../asset-management/AssetManagement.js')
    ),
    permission: [],
  },
  //   {
  //     path: '/accounting/cash-flow-monthly',
  //     Component: lazy(() => import('./../../accounting/CashFlowMonthly.js')),
  //     permission: [],
  //   },
  //   {
  //     path: '/accounting/profit-loss',
  //     Component: lazy(() => import('./../../accounting/ProfitLoss.js')),
  //     permission: [],
  //   },
  //   {
  //     path: '/accounting/balance-sheet',
  //     Component: lazy(() => import('./../../accounting/BalanceSheet.js')),
  //     permission: [],
  //   },
  // {
  // 	path: "/user/view",
  // 	Component: lazy(() => import("./../../user/view_users.js")),
  // 	permission: [
  // 		permissionsAllowed.view_customer,
  // 		permissionsAllowed.add_customer,
  // 	],
  // },
];
