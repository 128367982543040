import React from "react";

const TransactionRow = () => {
	// grid dashCusGrid
	return (
		<article
			// style={{ minWidth: "1050px" }}
			style={{ width: "100%" }}
			className="rounded-md mb-5 px-5 pr-8 py-6 shadow-md  flex justify-between  bg-white"
		>
			<span className="h-10 bg-pink-200 w-10 rounded-full"></span>
			<div>
				<p className="small-font3 cusCol">Reference</p>
				<span className="small-font3 font-bold">09302</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Borrower</p>
				<span className="small-font3 font-bold">Felix Ola-Kunle</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Principal</p>
				<span className="small-font3 font-bold">10,000.00</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Interest</p>
				<span className="small-font3 font-bold">5% per Month</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Disbursed</p>
				<span className="small-font3 font-bold">54,000</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Payment Due</p>
				<span className="small-font3 font-bold">100,000</span>
			</div>
			<div>
				<p className="small-font3 cusCol">Amount</p>
				<span className="small-font3 font-bold">12,000,000</span>
			</div>
			<div className="flex items-center">
				<p className="small-font2 font-bold">not issued</p>
			</div>
			<button className="cusBtn text-white rounded-lg px-8 text-center py-2">pay</button>
			<span className="flex items-center">
				<div>
					<span className="w-1 h-1 rounded-sm mb-1 ml-8 force-black-bg block"></span>
					<span className="w-1 h-1 rounded-sm mb-1 ml-8 force-black-bg block"></span>
					<span className="w-1 h-1 rounded-sm ml-8 force-black-bg block"></span>
				</div>
			</span>
		</article>
	);
};

export default TransactionRow;
