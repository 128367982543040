import { lazy } from "react";

export default [
	{
		path: "/view-expenses",
		Component: lazy(() => import("./../../expenses/view_expenses.js")),
		permission: [],
	},
	{
		path: "/edit-expenses/:id",
		Component: lazy(() => import("./../../expenses/edit_expense.js")),
		permission: [],
	},
	{
		path: "/add-expense",
		Component: lazy(() => import("./../../expenses/add_expense.js")),
		permission: [],
	},
	{
		path: "/payroll-dashboard",
		Component: lazy(() => import("./../../payroll/payroll_dashboard.js")),
		permission: [],
	},
	{
		path: "/view-payroll",
		Component: lazy(() => import("./../../payroll/view_payroll.js")),
		permission: [],
	},
	{
		path: "/edit-payroll/:id",
		Component: lazy(() => import("./../../payroll/edit_payslip.js")),
		permission: [],
	},
	{
		path: "/create-payslip",
		Component: lazy(() => import("./../../payroll/create_payslip.js")),
		permission: [],
	},
	{
		path: "/generate-payroll-pdf/:id",
		Component: lazy(() => import("./../../payroll/generate_payroll_pdf.js")),
		permission: [],
	},
];
