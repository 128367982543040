export default {
	sidebar_data: [
		{
			icon: require("../../assets/home-solid.svg"),
			name: "Home",
			route: "/customer",
			children: [],
		},
		{
			icon: require("../../assets/loan.svg"),
			name: "Loans",
			route: "/customer/loan",
			children: [
				{ name: "Apply for Loans", route: "/customer/loan" },
				// 22191231887
			],
		},
		{
			icon: require("../../assets/credit-card-payment.svg"),
			name: "Repayments",
			route: "/customer/repayments",
			children: [],
		},
		// {
		// 	icon: require("../../assets/profit-report.svg"),
		// 	name: "Reports",
		// 	route: "/customer/reports",
		// 	children: [],
		// },
		// {
		// 	icon: require("../../assets/user.svg"),
		// 	name: "Manage Profile",
		// 	route: "/customer/manage-profile",
		// 	children: [],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/customer.svg"),
		//     name: "Borrowers",
		//     route: "/borrower",
		//     children: [
		//         { name: "Create Borrower", route: "/borrower/create" },
		//         { name: "View Borrowers", route: "/borrower/view" },
		//         {
		//             name: "Create borrower group",
		//             route: "/borrower/group/create",
		//         },
		//         { name: "View borrower group", route: "/borrower/group/view" },
		//         { name: "Send sms", route: "/borrower/send_sms" },
		//         { name: "Send email", route: "/borrower/send_email" },
		//         { name: "Invite customer", route: "/borrower/invite_customer" },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/loan.svg"),
		//     name: "Loans",
		//     route: "/loan",
		//     children: [
		//         { name: "Start a loan", route: "/loan/start" },
		//         { name: "View existing loans", route: "/loan/all" },
		//         { name: "Due loans", route: "/loan/due-loans" },
		//         { name: "Missed Repayments", route: "/loan/missed-repayment" },
		//         { name: "Loans in arrears", route: "/loan/loans-in-arrears" },
		//         { name: "No repayments", route: "/loan/no-repayments" },
		//         { name: "Late loans", route: "/loan/late-loans" },
		//         { name: "Past Maturity" },
		//         {
		//             name: "Principal outstanding",
		//             route: "/loan/principal-outstanding",
		//         },
		//         { name: "Loan Calculator", route: "/loan/loan-calculator" },
		//         { name: "Approved loans", route: "/loan/approved-loans" },
		//         { name: "Loan Summary", route: "/loan/summary" },
		//         { name: "Guarantors", route: "/loan/guarantor/all" },
		//         { name: "Loan comments", route: "/loan/loan-comments" },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/money.svg"),
		//     name: "Disbursements",
		//     route: "/disbursement",
		//     children: [
		//         { name: "Disburse a loan", route: "/disbursement/loan" },
		//         {
		//             name: "Disbursement History",
		//             route: "/disbursement/history",
		//         },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/repayments.svg"),
		//     name: "Repayments",
		//     route: "/repayment",
		//     children: [
		//         { name: "View all repayments", route: "/repayment/all" },
		//         { name: "Make repayments", route: "/repayment/make" },
		//         { name: "Bulk repayments" },
		//         { name: "Approve repayments", route: "/repayment/approve-repayment" },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/savings.svg"),
		//     name: "Savings",
		//     children: [
		//         { name: "View Savings Accounts" },
		//         { name: "Add Savings Accounts" },
		//         { name: "Savings Report" },
		//         { name: "Savings Product Report" },
		//         { name: "Cash Safe Management" },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/transactions.svg"),
		//     name: "Transactions",
		//     children: [
		//         { name: "View Savings Transactions" },
		//         { name: "Approve Savings Transactions" },
		//     ],
		// },
		// {
		//     icon: require("./../assets/sidebar-icons/reports.svg"),
		//     name: "Reports",
		//     children: [
		//         { name: "Borrower's Report", route: "/report/borrower" },
		//         { name: "Loan Report", route: "/report/loan_report" },
		//         { name: "Loan Arrears Aging Report", route: "/report/loan_arrears_aging_report" },
		//         { name: "Collections Report", route: "/report/collection_report" },
		//         { name: "Collectors Report", route: "/report/collections_by_staff_report" },
		//         { name: "Deferred Income" },
		//         { name: "Deferred Income Monthly", route: "/report/deferred_monthly_income" },
		//         { name: "Disbursement Report", route: "/report/disbursement_report" },
		//         { name: "Fees Report", route: "/report/fees_report" },
		//         { name: "Loan Officer Report", route: "/report/loan_officer_report" },
		//         { name: "Loan Product Report", route: "/report/loan_product_report" },
		//         { name: "Monthly Report", route: "/report/monthly_report" },
		//         { name: "Outstanding Reports", route: "/report/outstanding_report" },
		//         { name: "Portfolio at Risk", route: "/report/portfolio_at_risk_report" },
		//         { name: "At a Glance Report", route: "/report/at_a_glance_report" },
		//         { name: "All Entries", route: "/report/all_entries_report" },
		//     ],
		// },
	],
	// bottom_sidebar_data: [
	//     { name: "Investments" },
	//     { name: "Collateral Register", route: "/collateral/view" },
	//     { name: "Asset Management" },
	//     { name: "Other Income" },
	//     { name: "Staff" },
	//     { name: "Expenses" },
	//     { name: "Accounting" },
	// ],
};
