import React, { useContext, useState, useEffect } from "react";
import DashboardCard from "./components/molecules/dashboardCard";
import TransactionRow from "./components/molecules/transactionRow";
import { AppContext } from "../context/app_context";
import { getUserRecord } from "../utils/api";
const dashboardTitle = ["Pending Loan Balance", "Total Repayments", "Due Payment", "Amount Paid"];
const dashboardIndex = ["pending_loan_balance", "total_repayments", "due_payments", "amount_paid"];
const Dashboard = () => {
	const { appState } = useContext(AppContext);
	console.log(appState);
	const [dashboardNumbers, setDashboardNumbers] = useState({});
	console.log(appState);
	useEffect(() => {
		getUserRecord()
			.then((response) => {
				console.log(response.data);
				setDashboardNumbers(response.data);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);
	return (
		<section className="px-8">
			<div className="mt-16">
				<h4 className="font-bold text-lg  text-gray-600">
					<span>
						Welcome, {appState.user.first_name} {appState.user.last_name}
					</span>
					<span>{appState.user.borrower_name}</span>
				</h4>
				<section className="grid-cols-2 mt-10 md:grid-cols-4 gap-4 grid w-full">
					{Object.keys(dashboardNumbers).length > 0 &&
						dashboardTitle.map((title, index) => {
							const objref = dashboardIndex[index];
							console.log(dashboardNumbers);
							return (
								<>
									<DashboardCard value={dashboardNumbers[objref]} label={title} />
								</>
							);
						})}
				</section>
				{/* <section className="mt-16">
					<h6 className="font-normal text-xs">Recent Transaction</h6>

					<div className="mt-6 overflow-x-auto">
						<TransactionRow />
						<TransactionRow />
					</div>
				</section> */}
				<div></div>
			</div>
		</section>
	);
};

export default Dashboard;
