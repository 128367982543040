import { useEffect, useState } from "react";

import {
	getBorrowerReport,
	getDisbursementReport,
	getAtAGlanceReport,
	getLoanReport,
	getOutstandingReport,
	getAllEntriesReport,
	getLoanProductReport,
	getCollectionReport,
	getLoanOfficerReport,
	dueLoanNoPayment,
	fetchCollateral,
	fetchCountry,
	fetchDisbursementHistory,
	fetchLoanFees,
	fetchLoanSummary,
	getAllBranches,
	getBorrowers,
	getBorrowersGroup,
	getDueLoans,
	getLoanComments,
	getLoanGuarantor,
	getLoanOfficer,
	getLoanRepayment,
	getLoans,
	getLoansInArrears,
	getLoanType,
	getPrincipalOutstanding,
	getStaff,
	getUsers,
	searchBorrowersGroup,
} from "../utils/api";
import { useApiCall } from "./api_hooks";
import { isInfo } from "../utils/validation";

function useGeneralFetch(getData, initialData = []) {
	const [data, setData] = useState(initialData);
	const callApi = useApiCall();
	const fetchData = () => {
		callApi.callApi(getData, (data) => setData(data));
	};
	useEffect(() => {
		fetchData();
	}, []);
	const refetch = () => fetchData();

	return { data, loading: callApi.loading, refetch };
}
function useGeneralSearch(search, getData) {
	const [data, setData] = useState([]);
	const callApi = useApiCall();

	const fetchData = () =>
		callApi.callApi(
			() => getData(search),
			(data) => setData(data)
		);
	useEffect(() => {
		fetchData();
	}, [search]);
	const refetch = () => fetchData();
	return { data, loading: callApi.loading, refetch };
}
export function useBranches() {
	return useGeneralFetch(getAllBranches);
}
export function useLoanType() {
	return useGeneralFetch(getLoanType);
}

export function useBorrowers(search) {
	return useGeneralSearch(search, getBorrowers);
}

export function useStaff(search) {
	const [staff, setStaff] = useState([]);
	const callApi = useApiCall();
	const fetchStaff = () => {
		callApi.callApi(
			() => getStaff(search),
			(data) => {
				setStaff(data);
			}
		);
	};
	useEffect(fetchStaff, [search]);
	return { staff, loading: callApi.loading };
}
export function useBorrowerGroups(search) {
	const [borrowerG, setBorrowerG] = useState([]);

	const callApi = useApiCall();
	const fetchBorrowerG = () => {
		callApi.callApi(
			() => (!!search ? searchBorrowersGroup(search) : getBorrowersGroup()),
			(data) => {
				console.log(data);
				setBorrowerG(data);
			}
		);
	};
	useEffect(fetchBorrowerG, [search]);

	return {
		borrowerGroup: borrowerG,
		loading: callApi.loading,
		fetch: fetchBorrowerG,
	};
}

export function useLoan(search = `[{"searchTerm": "" ,"searchType":"ref"}]`) {
	return useGeneralSearch(search, getLoans);
}

export function useLoanOfficers() {
	return useGeneralFetch(getLoanOfficer);
}
export function useDisbursementHistory() {
	return useGeneralFetch(fetchDisbursementHistory);
}

export function useLoanRepayments(search) {
	return useGeneralSearch(search, getLoanRepayment);
}

export function useCountry() {
	return useGeneralFetch(fetchCountry);
}
export function useBorrowerReport() {
	return useGeneralFetch(getBorrowerReport);
}
export function useLoanReport() {
	return useGeneralFetch(getLoanReport);
}
export function useCollectionReport() {
	return useGeneralFetch(getCollectionReport);
}
export function useLoanOfficerReport() {
	return useGeneralFetch(getLoanOfficerReport);
}
export function useLoanProductReport() {
	return useGeneralFetch(getLoanProductReport);
}
export function useAllEntriesReport() {
	return useGeneralFetch(getAllEntriesReport);
}
export function useDisbursementReport() {
	return useGeneralFetch(getDisbursementReport);
}
export function useOutstandingReport() {
	return useGeneralFetch(getOutstandingReport);
}
export function useAtAGlanceReport() {
	return useGeneralFetch(getAtAGlanceReport);
}

export function useGuarantor() {
	return useGeneralFetch(getLoanGuarantor);
}
export function useUsers(search) {
	return useGeneralSearch(search, getUsers(search));
}
export function useDueLoans(search) {
	return useGeneralSearch(search, getDueLoans);
}

export function useDueLoansNoPayment() {
	return useGeneralFetch(dueLoanNoPayment);
}
export function usePrincipalOutstanding() {
	return useGeneralFetch(getPrincipalOutstanding);
}
export function useLoansInArrears(search) {
	return useGeneralSearch(search, getLoansInArrears);
}

export function useLoanComments(search) {
	return useGeneralSearch(search, getLoanComments);
}

export function useLoanFees() {
	return useGeneralFetch(fetchLoanFees);
}

export function useLoanCollaterals(search) {
	return useGeneralSearch(search, fetchCollateral);
}

export function useLoanSummary() {
	return useGeneralFetch(fetchLoanSummary, {});
}

export function useHandleFormInput(initialState = {}) {
	const [formInformation, setFormInformation] = useState(initialState);
	const [error, setError] = useState({});
	const changeFormInformation = (e) => {
		setFormInformation({
			...formInformation,
			[e.target.name]: e.target.value,
		});
	};
	const validateFields = () => {
		const fieldErrors = isInfo(formInformation);
		setError(fieldErrors);
	};

	return [formInformation, changeFormInformation, error, validateFields];
}
