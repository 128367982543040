import { appConfig } from "./constants";

export const extractToken = (token_string) => {
	return token_string.split(" ")[1];
};

export const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: "white",
		color: "#000000",
		borderRadius: "none",
		borderColor: "#e2e8f0",
		borderWidth: "1px",
		outline: "none",
		height: 43,
	}),
	option: (styles, {}) => {
		return {
			...styles,
			backgroundColor: "#fff",
			cursor: "pointer",
			color: "#718096",
			cursor: "default",
			border: "none",
			outline: "none",

			":active": {
				...styles[":active"],
				backgroundColor: "#edf2f7",
			},
			":hover": {
				...styles[":hover"],
				backgroundColor: "#edf2f7",
			},
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: "#edf2f7",
			color: "#4a5568",
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: "#4a5568",
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: "#f56565",
		":hover": {
			backgroundColor: "#edf2f7",
			color: "#f56565",
		},
	}),
};

export const handleSidebar = (arr) => {
	const response =
		arr.length > 0
			? arr.reduce((acc, cur, index) => {
					// console.log(acc, cur);
					return [...acc, ...cur.permission];
			  }, [])
			: [];

	if (!response) {
		debugger;
	} else {
		return response;
	}
};

export const convertToCommaSeperatedNumber = (value) => {
	console.log(value);
	if (value === null || value === undefined) {
		return "";
	}
	return parseFloat(parseFloat(value).toFixed(2)).toLocaleString();
	// return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const checkDocumentType = (name) => {
	const fileArr = name.split(".");
	const docExt = fileArr[fileArr.length - 1];
	console.log(docExt);
	let result = false;
	if (
		docExt.toLowerCase().includes("jpg") ||
		docExt.toLowerCase().includes("jpeg") ||
		docExt.toLowerCase().includes("png") ||
		docExt.toLowerCase().includes("pdf") ||
		docExt.toLowerCase().includes("docx") ||
		docExt.toLowerCase().includes("doc")
	) {
		result = true;
	}
	return result;
};
export const comfirmDocumentToBeDoc = (name) => {
	const fileArr = name.split(".");
	const docExt = fileArr[fileArr.length - 1];
	console.log(docExt);
	let result = false;
	if (docExt.toLowerCase().includes("docx") || docExt.toLowerCase().includes("doc")) {
		result = true;
	}
	return result;
};

// function numberWithCommas(x) {
// 	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }

export const storageContainsToken = () => {
	return !!localStorage.getItem(appConfig.token_name2);
};

export const getTokenFromStorage = () => {
	return localStorage.getItem(appConfig.token_name2);
};

export const setTokenToStorage = (token) => {
	localStorage.setItem(appConfig.token_name2, token);
};

export const removeTokenFromStorage = () => {
	localStorage.removeItem(appConfig.token_name2);
};

export function titleCase(str) {
	return str
		.toLowerCase()
		.split(" ")
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(" ");
}

export function getFileLink(link) {
	return link.slice(link.indexOf("http") || 0);
}

export const isEmpty = (val) => {
	if (typeof val === "string") {
		return !!!val.trim().length;
	} else if (typeof val === "number") {
		return !!!val;
	} else {
		return !!!val.length;
	}
};
export const rearrangeDate = (val) => {
	const dateArr = val.split("-");
	return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
};

export const confirmIfStaffOrCustomer = () => {
	const userObject = JSON.parse(localStorage.getItem("staffDetails"));
	let response = null;
	if (userObject.hasOwnProperty("staff_id") && userObject.hasOwnProperty("staff_name")) {
		response = "staff";
	} else {
		response = "customer";
	}
	return response;
};
export const confirmIfStaffOrCustomer2 = (user) => {
	const userObject = user;
	let response = null;
	if (user.is_staff) {
		response = "staff";
	} else {
		response = "customer";
	}
	return response;
};

export const navigateByStatus = (userObject) => {
	let to = "";

	if (!userObject.create_borrower) {
		to = "/customer/loan/create-borrower";
	} else if (userObject.create_borrower && !userObject.working_status_id) {
		to = "/customer/loan/create-borrower";
	} else if (
		userObject.create_borrower &&
		userObject.working_status_id &&
		!userObject.guarantor_added
	) {
		to = "/customer/loan/add-guarantor";
	} else if (
		userObject.create_borrower &&
		userObject.working_status_id &&
		userObject.guarantor_added &&
		!userObject.apply_for_loan
	) {
		to = "/customer/loan/create-loan";
	} else if (
		userObject.create_borrower &&
		userObject.working_status_id &&
		userObject.guarantor_added &&
		userObject.apply_for_loan
	) {
		to = "#";
	}

	return to;
};

export function parser_v2(variables = [""], text = "", parser_string = ["{{", "}}"]) {
	const error_message = "WRONG VARIABLE USED";
	const wrong = [];
	let newText = text;
	console.log(newText);
	//
	//
	var opening = parser_string[0];
	var closing = parser_string[1];
	var open = opening.replace(/[-[\]()*\s]/g, "\\$&").replace(/\$/g, "\\$");
	var close = closing.replace(/[-[\]()*\s]/g, "\\$&").replace(/\$/g, "\\$");
	var r = new RegExp(open + "(.+?)" + close, "g");
	//, r = /\{\{(.+?)\}\}/g
	var matches = newText.match(r) || [];
	matches.forEach(function (match) {
		var key = match.substring(opening.length, match.length - closing.length).trim(); //chop {{ and }}
		if (!variables.includes(key)) {
			newText = newText.replace(match, `<${error_message}: ${key}>`);
			text = text.replace(match, `<${error_message}: ${key}>`);
			wrong.push(key);
		}
	});
	return [newText, text, wrong];
}
