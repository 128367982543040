export const validateLoginFormData = (values) => {
	let validationErrors = {};
	let noOfError = 0;

	if (!values.email) {
		validationErrors.email = "Email address is required";
		noOfError += 1;
	} else if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		validationErrors.email = "Please enter a valid email address";
		noOfError += 1;
	}

	if (!values.password) {
		validationErrors.password = "Password is required";
		noOfError += 1;
	} else if (values.password.length < 6) {
		validationErrors.password = "password is too short";
		noOfError += 1;
	}

	return { validationErrors, noOfError };
};
