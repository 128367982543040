import React, { useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import Dots from "../../../components/dots";
import { useClickOutside } from "../../../hooks.js/useClickOutside";
import { getCurrentRepaymentStaff, loanRestructuringRequest } from "../../../utils/api-request";
import { useHistory } from "react-router-dom";

const LastChildViewLoans = ({ data, fetchCurrentRepayment, dispatch, refetchData }) => {
	const { id, status } = data;
	const tdc = useRef();

	const history = useHistory();

	const [showOptions, setShowOptions] = useState(false);

	const { addToast } = useToasts();
	useClickOutside(tdc, () => {
		setShowOptions(false);
	});

	const fetchCurrentRepaymentAndSetData = async (id) => {
		dispatch({
			type: "CHANGE",
			payload: { showFullPageLoader: true },
		});

		const loanId = id;

		try {
			const response = await getCurrentRepaymentStaff(id);

			console.log(response);
			const { total_period_payment } = response.data.data;
			dispatch({
				type: "CHANGE",
				payload: {
					repaymentData: { amount: total_period_payment, id: loanId },
					showFullPageLoader: false,
					showRepaymentModal: true,
				},
			});

			// handleRepData(response.data.data);
		} catch (error) {
			dispatch({
				type: "CHANGE",
				payload: { showFullPageLoader: false },
			});
			// console.log(error);
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	const handleRestructuring = async (action) => {
		let text;
		let url;

		const loanId = id;

		if (action === "APPROVE") {
			text = "approved";
			url = `/loan/restured-loan/approve/${loanId}`;
		} else if (action === "REJECT") {
			text = "rejected";
			url = `/loan/restured-loan/dis-approve/${loanId}`;
		}

		dispatch({ type: "CHANGE", payload: { showFullPageLoader: true } });
		console.log(text, url);

		try {
			const response = await loanRestructuringRequest(url);
			if (response.status.toString().startsWith("2")) {
				addToast(`You have successfully ${text} the restructured loan`, {
					appearance: "success",
					autoDismiss: true,
				});
			}
			dispatch({ type: "CHANGE", payload: { showFullPageLoader: false } });
			refetchData();
		} catch (error) {
			dispatch({ type: "CHANGE", payload: { showFullPageLoader: false } });
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	return (
		<td ref={tdc} className="relative">
			<Dots
				onClick={() => {
					setShowOptions(!showOptions);
				}}
				style={{ position: "relative" }}
			/>

			{showOptions &&
				(status === "disbursed" || status === "restructured" || status === "rejected") && (
					<ul
						style={{ bottom: "70%", left: "-40%" }}
						className="bg-white p-5 w-56 rounded absolute text-left z-50 shadow-lg"
					>
						{status === "disbursed" && (
							<>
								<li
									onClick={() => {
										setShowOptions(false);
										fetchCurrentRepayment(id);
									}}
									className="hover:bg-gray-300 mb-3 text-xs cursor-pointer"
								>
									Get Current repayment
								</li>

								<li
									onClick={() => {
										fetchCurrentRepaymentAndSetData(data.id);
									}}
									className="hover:bg-gray-300 mb-3 text-xs cursor-pointer"
								>
									Make repayment
								</li>

								<li
									onClick={() => {
										// handleDecline();
										// setShowOptions(!showOptions);
									}}
									className="hover:bg-gray-300 mb-3 text-xs cursor-pointer"
								>
									Setup Direct Debit
								</li>
								<li
									onClick={() => {
										history.push(`/customer/repayments/${id}`);
									}}
									className="hover:bg-gray-300 mb-3 text-xs cursor-pointer"
								>
									View Repayment
								</li>
							</>
						)}

						{(status === "restructured" || status === "rejected") && (
							<>
								<li
									onClick={() => {
										handleRestructuring("APPROVE");
									}}
									className="hover:bg-gray-300 text-xs mb-3 cursor-pointer"
								>
									Accept Loan Restructuring
								</li>
								{status === "restructured" && (
									<li
										onClick={() => {
											handleRestructuring("REJECT");
										}}
										className="hover:bg-gray-300 mb-3 text-xs cursor-pointer"
									>
										Reject Loan Restructuring
									</li>
								)}
							</>
						)}
					</ul>
				)}
		</td>
	);
};

export default LastChildViewLoans;
