import React, { useEffect } from "react";
import { globalGetApi } from "../utils/api-request";
import { useReducerState } from "./useReducerState";

export const useFetch = (url, initialData) => {
	const fetchData = useReducerState({ data: initialData, loading: false });

	const fetchFromApi = async () => {
		fetchData.set({ loading: true });
		try {
			const response = await globalGetApi(url);

			if (response.status.toString().startsWith("2")) {
				const result = response.data.data;
				console.log(result);
				fetchData.set({ data: result, loading: false });
			}
		} catch (error) {
			fetchData.set({ loading: false });
			console.log(error);
		}
	};

	useEffect(() => {
		fetchFromApi();
	}, [url]);

	return { data: fetchData.use.data, loading: fetchData.use.loading };
};
