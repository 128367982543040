import { lazy } from 'react';
import { permissionsAllowed } from '../../../utils/permissions.js';

export default [
  {
    path: '/accounting/cash-flow-accumulated',
    Component: lazy(() => import('./../../accounting/CashFlowAccumulated.js')),
    permission: [],
  },
  {
    path: '/accounting/cash-flow-monthly',
    Component: lazy(() => import('./../../accounting/CashFlowMonthly.js')),
    permission: [],
  },
  {
    path: '/accounting/profit-loss',
    Component: lazy(() => import('./../../accounting/ProfitLoss.js')),
    permission: [],
  },
  {
    path: '/accounting/balance-sheet',
    Component: lazy(() => import('./../../accounting/BalanceSheet.js')),
    permission: [],
  },
  // {
  // 	path: "/user/view",
  // 	Component: lazy(() => import("./../../user/view_users.js")),
  // 	permission: [
  // 		permissionsAllowed.view_customer,
  // 		permissionsAllowed.add_customer,
  // 	],
  // },
];
