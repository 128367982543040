import React, { useReducer } from "react";

const reducer = (state, action) => {
	switch (action.type) {
		case "CHANGE": {
			return { ...state, ...action.payload };
		}
		default: {
			return { ...state };
		}
	}
};

export const useReducerState = (initial_state) => {
	const [appState, appDispatch] = useReducer(reducer, initial_state);

	const dispatch = (payload) => {
		appDispatch({ type: "CHANGE", payload });
	};

	const reset = () => {
		appDispatch({ type: "CHANGE", payload: initial_state });
	};

	return {
		use: appState,
		set: dispatch,
		reset,
	};
};
