import React from "react";
import FusionCharts from "fusioncharts";
import ReactFC from "react-fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
// import PowerCharts from "fusioncharts/fusioncharts.powercharts";
// import TreeMap from 'fusioncharts/fusioncharts.treemap';
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFusioncharts from "react-fusioncharts";
// import { dataSource } from "./datasource";

// charts(FusionCharts);
ReactFC.fcRoot(FusionCharts, charts, FusionTheme, PowerCharts);

const SunBurst = ({ data }) => {
	const newData = data.filter((each) => {
		return each.value !== 0;
	});
	const dataSource = {
		chart: {
			caption: "",
			subcaption: "",
			showplotborder: "1",
			theme: "fusion",
			type: "sunburst",
			// width: "100",
			// height: "100",
		},
		// data: newData,
		// data: [
		// 	{
		// 		id: "0.0",
		// 		parent: "",
		// 		name: "The World",
		// 		value: 7542011839,
		// 	},
		// 	{
		// 		id: "1.3",
		// 		parent: "0.0",
		// 		name: "Asia",
		// 		value: 4503248822,
		// 	},
		// 	{
		// 		id: "1.1",
		// 		parent: "0.0",
		// 		name: "Africa",
		// 		value: 1256268025,
		// 	},
		// 	{
		// 		id: "1.2",
		// 		parent: "0.0",
		// 		name: "America",
		// 		value: 1006801064,
		// 	},
		// 	{
		// 		id: "1.4",
		// 		parent: "0.0",
		// 		name: "Europe",
		// 		value: 743253404,
		// 	},
		// ],
		styles: {
			definition: [
				{
					name: "myHTMLFont",
					type: "font",
					ishtml: "1",
				},
			],
			application: [
				{
					toobject: "TOOLTIP",
					styles: "myHTMLFont",
				},
			],
		},
	};
	dataSource.data = newData;
	console.log(data);
	console.log(dataSource);

	return (
		<div className="pt-10 pl-20">
			<ReactFusioncharts
				type="sunburst"
				width="100%"
				height="100%"
				dataFormat="JSON"
				dataSource={dataSource}
			/>
			{/* (<ReactFC {...dataSource} />) */}
		</div>
	);
};

// class SunBurst extends React.Component {
//     render() {
//       return (
//         <ReactFusioncharts
//           type="sunburst"
//           width="100%"
//           height="100%"
//           dataFormat="JSON"
//           dataSource={dataSource}
//         />
//       );
//     }
//   }

export default SunBurst;
