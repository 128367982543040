import React, { useEffect, useState } from "react";
// import DashboardCard from "./components/molecules/dashboardCard";
import TransactionRow from "../components/molecules/transactionRow";
import { usePaystackPayment } from "react-paystack";
import { LoaderOverlay, LoaderSpinner } from "../../components/loader";
import { addDebitCard, getAllRepaymentsCustomer } from "../../utils/api-request";
import { useToasts } from "react-toast-notifications";
import { ExtractUserContext } from "../../context/app_context";
import { useHistory } from "react-router-dom";
import { Modal } from "../../components/modal";
// import ContinueModal from "./continue-modal";
import TableRowRepaymentCustomer from "./repayment-components/table-row-repayment-customer";
import TableOptionsRepaymentCustomer from "./repayment-components/table-options-repayment-customer";

const Repayments = () => {
	const history = useHistory();
	const { addToast } = useToasts();

	const [loading, setLoading] = useState("IDLE");
	const [result, setResult] = useState([]);

	const { appState } = ExtractUserContext();

	const noRepayments = loading === "RESOLVED" && result.length === 0;
	const showRepayments = loading === "RESOLVED" && result.length > 0;

	useEffect(() => {
		const fetchRepayments = async () => {
			setLoading("FETCHING");
			try {
				const response = await getAllRepaymentsCustomer();
				console.log(response);
				setResult(response.data.data);
			} catch (error) {
				if (!error.response) {
					addToast("Kindly check your network and refresh", {
						appearance: "error",
						autoDismiss: true,
					});
				} else if (error.response) {
					addToast(error.response.data.errorMessage, {
						appearance: "error",
						autoDismiss: true,
					});
				} else {
					addToast("Operation failed", {
						appearance: "error",
						autoDismiss: true,
					});
				}
			} finally {
				setLoading("RESOLVED");
			}
		};

		fetchRepayments();
	}, []);

	return (
		<section className="px-8">
			<div className="mt-16">
				{/* <section className="mt-16">
					<h6 className="font-semibold force-black text-xs">Upcoming Repayments</h6>

					<div className="mt-6 overflow-x-auto">
						<TransactionRow />
					</div>
				</section> */}
				<section className="mt-10">
					<h6 className="font-semibold force-black text-xs">Recent Repayments</h6>

					{noRepayments && <p className="text-center"> You don't have any repayments.</p>}

					{loading === "FETCHING" && <LoaderSpinner />}

					{showRepayments && (
						<table
							style={{ position: "relative", top: "-35px" }}
							className={`cus-rep-table w-full`}
						>
							<thead>
								<tr className="text-gray-500  text-sm">
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{result.map((each) => {
									return (
										<TableRowRepaymentCustomer data={each}>
											<TableOptionsRepaymentCustomer />
										</TableRowRepaymentCustomer>
									);
								})}
							</tbody>
						</table>
					)}
				</section>
				<div></div>
			</div>
		</section>
	);
};

export default Repayments;
