import { lazy } from "react";

export default [
	{
		path: "/investor/dashboard",
		Component: lazy(() => import("./../../investment/investor/investment_dashboard.js")),
		permission: [],
	},
	// {
	// 	path: "/investor/view",
	// 	Component: lazy(() => import("./../../investment/investor/view_investor.js")),
	// 	permission: [],
	// },
	// {
	// 	path: "/investor/add",
	// 	Component: lazy(() => import("./../../investment/investor/AddInvestor.js")),
	// 	permission: [],
	// },
	// {
	// 	path: "/investor/edit/:id",
	// 	Component: lazy(() => import("./../../investment/investor/EditInvestor.js")),
	// 	permission: [],
	// },
	{
		path: "/investor/investor-profile",
		Component: lazy(() =>
			import("./../../investment/investor/investor_profile/ViewInvestorProfile.js")
		),
		permission: [],
	},
	{
		path: "/investor/investor-profile/add",
		Component: lazy(() =>
			import("./../../investment/investor/investor_profile/AddInvestorProfile")
		),
		permission: [],
	},
	{
		path: "/investor/investor-profile/edit/:id",
		Component: lazy(() =>
			import("./../../investment/investor/investor_profile/EditInvestorProfile.js")
		),
		permission: [],
	},
	{
		path: "/investor/sms",
		Component: lazy(() => import("./../../investment/investor/sms_investor.js")),
		permission: [],
	},
	{
		path: "/investor/email",
		Component: lazy(() => import("./../../investment/investor/email_investor.js")),
		permission: [],
	},
	{
		path: "/investor/invite",
		Component: lazy(() => import("./../../investment/investor/invite_investor.js")),
		permission: [],
	},
	// {
	// 	path: "/investor/investment-product",
	// 	Component: lazy(() =>
	// 		import("./../../investment/investor/investment_product/add_investment_product.js")
	// 	),
	// 	permission: [],
	// },
	// {
	// 	path: "/investor/investment-loan-product",
	// 	Component: lazy(() =>
	// 		import("./../../investment/investor/investment_product/add_loan_investment_product.js")
	// 	),
	// 	permission: [],
	// },
	{
		path: "/investor/investment-transaction-type",
		Component: lazy(() =>
			import(
				"./../../investment/investor/investment_product/add_investor_transaction_type.js"
			)
		),
		permission: [],
	},
	// {
	// 	path: "/investor/investment-account",
	// 	Component: lazy(() =>
	// 		import("./../../investment/investor/investment_product/add_investment_account.js")
	// 	),
	// 	permission: [],
	// },
	{
		path: "/investor/investment-account-fee",
		Component: lazy(() =>
			import("./../../investment/investor/investment_product/add_investment_account_fee.js")
		),
		permission: [],
	},
	{
		path: "/investor/investment-transaction-types",
		Component: lazy(() =>
			import("../../investment/investor/investor_type/investment_transaction_types.js")
		),
		permission: [],
	},
	{
		path: "/investment/add",
		Component: lazy(() => import("../../investment/investment/AddInvestmentAccount.js")),
		permission: [],
	},
	{
		path: "/investment/edit-investment-account/:id",
		Component: lazy(() => import("../../investment/investment/EditInvestmentAccount.js")),
		permission: [],
	},
	{
		path: "/investment/view",
		Component: lazy(() => import("../../investment/investment/ViewInvestmentAccount.js")),
		permission: [],
	},
	{
		path: "/investment/investment-transactions",
		Component: lazy(() =>
			import("../../investment/investor/investor_transaction/ViewInvestorTransaction.js")
		),
		permission: [],
	},
	{
		path: "/investment-account/deposit",
		Component: lazy(() =>
			import("./../../investment/investor/investor_transaction/CreditInvestorAccount.js")
		),
		permission: [],
	},
	{
		path: "/investment-account/withdrawal",
		Component: lazy(() =>
			import("./../../investment/investor/investor_transaction/DebitInvestorAccount.js")
		),
		permission: [],
	},
	{
		path: "/investment/loan-investment",
		Component: lazy(() =>
			import("./../../investment/investor/loan_investment/ViewLoanInvestment.js")
		),
		permission: [],
	},
	{
		path: "/investment/loan-investment/add",
		Component: lazy(() =>
			import("./../../investment/investor/loan_investment/AddLoanInvestment.js")
		),
		permission: [],
	},
];
