import React from "react";
import { ExtractUserContext } from "../../../context/app_context";
const getInitials = (first, last) => {
	const init = first.slice(0, 1);
	const init2 = last.slice(0, 1);
	return `${init.toUpperCase()}${init2.toUpperCase()}`;
};

const TableRowViewLoans = ({ children, data }) => {
	const {
		disbursement_type,
		initial_principal,
		loan_duration,
		loan_duration_period,
		status,
		repay_cycle,
	} = data;

	const formattedRepayCycle = `${repay_cycle.slice(0, 1).toUpperCase()}${repay_cycle
		.slice(1)
		.toLowerCase()}`;
	const { appState } = ExtractUserContext();

	const { first_name, last_name } = appState.user;
	return (
		<tr className="text-gray-600 pr-4 pl-6  text-sm">
			<td>
				<div className="bg-pink-200 text-white rounded-full w-10 h-10 flex justify-center items-center">
					<p className="text-purple-700">
						{getInitials(first_name, last_name)}
						{/* {getInitials(user.first_name, user.last_name)} */}
					</p>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Borrower</p>
					<span className="small-font3 font-bold">{`${first_name} ${last_name}`}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Duration</p>
					<span className="small-font3 font-bold">
						{`${loan_duration}
						${`${loan_duration_period.slice(0, 1).toUpperCase()}${loan_duration_period
							.slice(1)
							.toLowerCase()}`}`}
					</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Repayment Cycle</p>
					<span className="small-font3 font-bold">{formattedRepayCycle}</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Principal</p>
					<span className="small-font3 font-bold">
						₦{parseInt(initial_principal).toLocaleString()}
					</span>
				</div>
			</td>
			<td style={{ textAlign: "left" }}>
				<div>
					<p className="small-font3 cusCol">Status</p>
					<span className="small-font3 font-bold">{`${status
						.slice(0, 1)
						.toUpperCase()}${status.slice(1).toLowerCase()}`}</span>
				</div>
			</td>

			{children}

			{/* <TdLastChildLoan
            handleSideData={handleSideData}
            refetchData={refetchData}
            toggleLoader={toggleLoader}
            data={data}
        /> */}
		</tr>
	);
};

export default TableRowViewLoans;
