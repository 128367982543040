import React, { useRef, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import Dots from "../../../components/dots";
import { useClickOutside } from "../../../hooks.js/useClickOutside";

const TableOptionsRepaymentCustomer = () => {
	// const { id, user } = data;
	const tdc = useRef();

	const [showOptions, setShowOptions] = useState(false);

	const { addToast } = useToasts();
	useClickOutside(tdc, () => {
		setShowOptions(false);
	});
	const history = useHistory();
	return (
		<td ref={tdc} className="relative">
			<Dots
				onClick={() => {
					setShowOptions(!showOptions);
				}}
				style={{ position: "relative" }}
			/>
			{/* {showOptions && (
				<ul
					style={{ bottom: "70%", left: "-40%" }}
					className="bg-white p-5 w-56 rounded absolute text-center z-50 shadow-lg"
				>
					<li
						onClick={() => {
							// handleApprove();
							// setShowOptions(!showOptions);
						}}
						className="hover:bg-gray-300 mb-3 cursor-pointer"
					>
						Make repayment
					</li>
					<li
						onClick={() => {
							// handleDecline();
							// setShowOptions(!showOptions);
						}}
						className="hover:bg-gray-300 mb-3 cursor-pointer"
					>
						Setup Direct Debit
					</li>
				</ul>
			)} */}
		</td>
	);
};

export default TableOptionsRepaymentCustomer;
