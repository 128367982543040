import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import Unauthorized from "./unauthorized";
import { ExtractUserContext } from "../context/app_context";

const PrivateRoute = ({ permissions, children, ...rest }) => {
	const { appState } = ExtractUserContext();
	const userPermission = appState.user.permissions.length
		? appState.user.permissions.map((each) => {
				return each.permit_name;
		  })
		: [];
	const checkPermission = (allowed, userperm) => {
		if (allowed.length === 0) {
			return true;
		}
		return allowed.some((each) => {
			return userperm.includes(each);
		});
	};

	const showComponent = checkPermission(permissions, userPermission);

	return <>{showComponent ? children : <Unauthorized />}</>;
};

PrivateRoute.propTypes = {
	permissions: PropTypes.array,
	children: PropTypes.element.isRequired,
};

export default PrivateRoute;
