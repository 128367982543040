import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../context/app_context";
const Settings = () => {
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const { setUserObject } = useContext(AppContext);
	return (
		<span
			onClick={() => {
				setOpen(!open);
			}}
			className="mr-3 cursor-pointer flex relative items-center"
			to={"/customer"}
		>
			<img
				className="ml-5 mr-1 h-4"
				src={require("./../../assets/settings-24pxx.svg")}
				alt="settings"
			/>
			<span
				className={`${
					open ? "rotate-90" : "rotate-0"
				} transform ml-auto text-lg font-semibold`}
			>
				&rsaquo;
			</span>
			{open && (
				<div
					style={{ top: "55px", right: "5px" }}
					className="absolute w-40 shadow-md bg-white p-2"
				>
					<Link
						to={"/customer/manage-profile"}
						className="text-sm force-black hover:font-semibold block mb-3"
					>
						Manage Profile
					</Link>
					<Link
						to="/customer/change-password"
						className="text-sm hover:font-semibold force-black block mb-3"
					>
						Change Password
					</Link>
					<button
						onClick={() => {
							localStorage.removeItem("LOAN_CWS_TKN");
							// localStorage.removeItem("staffDetails");
							// setUserObject(null);
							history.push("/login");
						}}
						className="text-sm hover:font-semibold force-black"
					>
						Log Out
					</button>
				</div>
			)}
		</span>
	);
};

export default Settings;
