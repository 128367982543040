import "./styles/styles.css";
import "./styles/tailwind.css";
import customerRoutes from "./customer/routes/routes";
import { Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";

import { adminDashboardRoutes } from "./pages/main/main";
import axios from "axios";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Login } from "./pages/auth/login";
import ResetPassword from "./pages/auth/reset-password";
import { Register } from "./pages/auth/register";
import { Main } from "./pages/main/main.js";
import Customer from "./customer/customer";
import { AppContext } from "./context/app_context";
import ResetPassword2 from "./pages/auth/reset-password2";
import { getAndPersistUser } from "./utils/api";
import { removeTokenFromStorage, storageContainsToken } from "./utils/functions";
import { useToasts } from "react-toast-notifications";
import { appConfig } from "./utils/constants";
import VerifyEmail from "./pages/auth/verify_email";

const ccroutes = [
	"/customer/loan",
	"/customer/change-password",
	"/customer/view-profile",
	"/customer/payment",
	"/customer/reports",
	"/customer/repayments",
	"/customer/repayments/:id",
	"/customer/manage-profile",
	"/customer/loan-calculator",
	"/customer/loan/create-loan",
	"/customer/loan/create-borrower",
	"/customer/loan/add-guarantor",
];
function api(token) {
	const config = { baseURL: appConfig.base_url };
	if (token) {
		config.headers = { Authorization: `Bearer ${token}` };
	}
	const instance = axios.create(config);
	return instance;
}
function App() {
	const { setUserObject } = useContext(AppContext);
	const [expired, setExpired] = useState(false);
	// const history = useHistory();
	// console.log(history);
	const { addToast } = useToasts();
	useEffect(() => {
		const localToken = localStorage.getItem("LOAN_CWS_TKN");
		if (localToken) {
			// console.log(localToken);
			api(localToken)
				.get("/auth/logged_in_user")
				.then((res) => {
					setUserObject(res.data.data);
					console.log(res.data.data);
				})
				.catch((error) => {
					console.log(error.response);
					if (!error.response) {
						addToast("Kindly check your network and try again", {
							appearance: "error",
							autoDismiss: true,
						});
					} else if (error.response.status === 403) {
						removeTokenFromStorage();
						window.location = "/";
					} else {
						console.log(error.response);
						addToast("something went wrong .. check your console", {
							appearance: "error",
							autoDismiss: true,
						});
					}
					// if (
					// 	error.response &&
					// 	error.response.statusText === "Unauthorized"
					// ) {
					// 	// console.log(true, window.location);

					// 	if (window.location.pathname !== "/login") {
					// 		localStorage.removeItem("LOAN_TOKEN");
					// 		window.location.href = "/login";
					// 	}
					// }
				});
		}
	}, []);
	return (
		<div className="text-black">
			<Router>
				<Switch>
					{/* <Route exact path="/login" component={Login} /> */}

					<Route
						exact
						path={"/login"}
						render={(routeProps) => (
							<>
								{!storageContainsToken() ? (
									<Login {...routeProps} />
								) : (
									<Redirect to="/dashboard" />
								)}
							</>
						)}
					/>
					<Route
						exact
						path="/forgot-password"
						render={(routeProps) => (
							<>
								{!storageContainsToken() ? (
									<ResetPassword {...routeProps} />
								) : (
									<Redirect to="/dashboard" />
								)}
							</>
						)}
					/>
					<Route
						exact
						path="/reset-password/:token"
						render={(routeProps) => (
							<>
								{!storageContainsToken() ? (
									<ResetPassword2 {...routeProps} />
								) : (
									<Redirect to="/dashboard" />
								)}
							</>
						)}
					/>
					<Route
						exact
						path="/register"
						render={(routeProps) => (
							<>
								{!storageContainsToken() ? (
									<Register {...routeProps} />
								) : (
									<Redirect to="/dashboard" />
								)}
							</>
						)}
					/>

					{/* <Route exact path="/staff-invitation/:token" component={ResetPassword} /> */}
					{/* <Route exact path="/reset-password" component={ResetPassword} /> */}
					{/* <Route exact path="/reset-password/:token" component={ResetPassword2} /> */}
					{/* <Route exact path="/register" component={Register} /> */}

					<Route exact path="/verify-email" component={VerifyEmail} />
					<Route
						exact
						path={["/", "/dasboard", ...adminDashboardRoutes]}
						component={Main}
					/>
					<Route exact path={["/customer", ...ccroutes]} component={Customer} />
					<Route exact path="/customer" component={Customer} />
					<Main />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
