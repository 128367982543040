export const validateRegisterFormData = (values) => {
	let validationErrors = {};
	let noOfError = 0;
	// console.log(values);

	if (!values.first_name) {
		validationErrors.first_name = "First name is required";
		noOfError += 1;
	}

	if (!values.last_name) {
		validationErrors.last_name = "Last name is required";
		noOfError += 1;
	}

	if (!values.agree) {
		validationErrors.agree = "Please agree with our terms and condition to continue";
		noOfError += 1;
	}

	if (!values.phone) {
		validationErrors.phone = "Phone number is required";
		noOfError += 1;
	} else if (values.phone && values.phone.length < 11) {
		validationErrors.phone = "Please enter a valid phone number";
		noOfError += 1;
	}

	if (!values.username) {
		validationErrors.username = "Please enter a username";
		noOfError += 1;
	}

	if (!values.email) {
		validationErrors.email = "Email address is required";
		noOfError += 1;
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		validationErrors.email = "Please enter a valid email address";
		noOfError += 1;
	}

	if (!values.password) {
		validationErrors.password = "Password is required";
		noOfError += 1;
	} else if (values.password.length < 6) {
		validationErrors.password = "password is too short";
		noOfError += 1;
	}

	return { validationErrors, noOfError };
};
