import { lazy } from "react";

export default [
	{
		path: "/savings/dashboard",
		Component: lazy(() => import("./../../savings/savings_dashboard.js")),
		permission: [],
	},
	{
		path: "/savings/view-savings-accounts",
		Component: lazy(() => import("./../../savings/saving_accounts/view_savings_accounts.js")),
		permission: [],
	},
	{
		path: "/savings/view-savings-accounts/edit/:id",
		Component: lazy(() => import("./../../savings/saving_accounts/EditSavingsAccount.js")),
		permission: [],
	},
	{
		path: "/savings/add-savings-account",
		Component: lazy(() => import("./../../savings/saving_accounts/add_savings_account.js")),
		permission: [],
	},
	{
		path: "/savings/add-lien",
		Component: lazy(() => import("./../../savings/saving_accounts/add_lien.js")),
		permission: [],
	},
	{
		path: "/savings/borrower-saving/:id",
		Component: lazy(() =>
			import(
				"../../savings/saving_accounts/borrower_savings_account/borrower_savings_account.js"
			)
		),
		permission: [],
	},
	{
		path: "/savings/savings-report",
		Component: lazy(() => import("./../../savings/savings_report/view_savings_report.js")),
		permission: [],
	},
	{
		path: "/savings/savings-product-report",
		Component: lazy(() =>
			import("./../../savings/savings_report/view_savings_product_report.js")
		),
		permission: [],
	},
	{
		path: "/savings/savings-account-profile/:id",
		Component: lazy(() =>
			import(
				"./../../savings/saving_accounts/savings_account_profile/view_savings_account_profile.js"
			)
		),
		permission: [],
	},
	{
		path: "/savings/savings-profile",
		Component: lazy(() => import("./../../savings/savings_profile/ViewSavingsProfile.js")),
		permission: [],
	},
	{
		path: "/savings/savings-profile/add",
		Component: lazy(() => import("./../../savings/savings_profile/AddSavingsProfile.js")),
		permission: [],
	},
	{
		path: "/savings/savings-profile/edit/:id",
		Component: lazy(() => import("./../../savings/savings_profile/EditSavingsProfile.js")),
		permission: [],
	},
	// {
	// 	path: "/savings/add-savings-product",
	// 	Component: lazy(() => import("./../../savings/add_savings_product.js")),
	// 	permission: [],
	// },
	// {
	// 	path: "/savings/add-savings-account-fees",
	// 	Component: lazy(() => import("./../../savings/add_savings_account_fees.js")),
	// 	permission: [],
	// },
	// {
	// 	path: "/savings/add-savings-account",
	// 	Component: lazy(() => import("./../../savings/add_savings_account.js")),
	// 	permission: [],
	// },
	{
		path: "/savings-transactions/view",
		Component: lazy(() =>
			import("./../../savings/savings_transactions/view_savings_transaction.js")
		),
		permission: [],
	},
	{
		path: "/savings-transactions/deposit",
		Component: lazy(() =>
			import("./../../savings/savings_transactions/credit_savings_account.js")
		),
		permission: [],
	},
	{
		path: "/savings-transactions/transfer",
		Component: lazy(() => import("./../../savings/savings_transactions/Transfer.js")),
		permission: [],
	},
	{
		path: "/savings-transactions/withdrawal",
		Component: lazy(() =>
			import("./../../savings/savings_transactions/debit_savings_account.js")
		),
		permission: [],
	},
	{
		path: "/savings/add-lien",
		Component: lazy(() => import("./../../savings/saving_accounts/add_lien.js")),
		permission: [],
	},
];
