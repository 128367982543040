export const permissionsAllowed = {
	add_branch: "add branch",
	edit_branch: "edit branch",
	delete_branch: "delete branch",
	view_branch: "view branch",

	add_role: "add role",
	delete_role: "delete role",
	edit_role: "edit role",
	view_role: "view role",

	add_staff: "add staff",
	edit_role_permission: "edit role permission",
	edit_staff: "edit staff",
	delete_staff: "delete staff",
	view_staff: "view staff",

	add_customer: "add customer",
	edit_customer: "edit customer",
	delete_customer: "delete customer",
	view_customer: "view customer",

	add_borrower: "add borrower",
	edit_borrower: "edit borrower",
	delete_borrower: "delete borrower",
	view_borrower: "view borrower",

	add_borrower_group: "add borrower group",
	edit_borrower_group: "edit borrower group",
	delete_borrower_group: "delete borrower group",
	view_borrower_group: "view borrower group",

	add_guarantor: "add guarantor",
	edit_guarantor: "edit guarantor",
	delete_guarantor: "delete guarantor",
	view_guarantor: "view guarantor",

	send_invite_to_staff: "send invite to staff",
	verify_staff_invite: "verify staff invite",
};
