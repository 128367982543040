import React from "react";
import ReactDOM from "react-dom";
import { AppProvider, AppContext } from "./context/app_context";
import "./index.css";
import { ToastProvider } from "react-toast-notifications";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<React.StrictMode>
		<ToastProvider>
			<AppProvider>
				<App />
			</AppProvider>
		</ToastProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
