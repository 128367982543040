import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Dots from "../../components/dots";
import { useClickOutside } from "../../hooks.js/useClickOutside";

// import Dots from "../../../../components/dots";
// import { useClickOutside } from "../../../../hooks.js/useClickOutside";

// import { useToasts } from "react-toast-notifications";
// import Dots from "../../../components/dots";
// import { useClickOutside } from "../../../hooks.js/useClickOutside";
// import { deleteExpense } from "../../../utils/api-request";

const TransLastChild = () => {
	const [showOptions, setShowOptions] = useState(false);

	const tdc = useRef();
	useClickOutside(tdc, () => {
		setShowOptions(false);
	});

	return (
		<td ref={tdc} className="relative">
			<Dots
				onClick={() => {
					setShowOptions(!showOptions);
				}}
				style={{ position: "relative" }}
			/>
			{/* {showOptions && (
				<ul
					style={{ bottom: "-35%", left: "-60%" }}
					className="bg-white p-5 w-56 rounded absolute text-left z-50 shadow-lg"
				>
					<li className="hover:bg-gray-300 text-xs mb-3 cursor-pointer">
						<Link to={`${url}${data.id}`}>Edit</Link>
					</li>

					<li
						onClick={() => {
							deleteFunc(data.id);
						}}
						className="hover:bg-gray-300 text-xs mb-3 cursor-pointer"
					>
						Delete
					</li>
					<li
						onClick={() => {
							deleteExpenseStaff(data.id);
						}}
						className="hover:bg-gray-300 text-xs mb-3 cursor-pointer"
					>
						Deactivate
					</li>
				</ul>
			)} */}
		</td>
	);
};

export default TransLastChild;
