import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { config } from "../../utils/constants";
import sidebar from "../sidebar/sidebarData";

export function AppSidebar() {
  const [activeItem, setActiveItem] = useState("");
  const [drop, setDrop] = useState(false);
  const history = useHistory();
  const isActive = item => {
    // console.log(history.location.pathname);
    // return history.location.pathname.startsWith(item.route);
    return history.location.pathname === item.route;
  };
  const handleItemClick = item => {
    console.log(item);
    if (activeItem === item.name) {
      setActiveItem("");
      return;
    }
    if (item.children && item.children.length) {
      setActiveItem(item.name);
    } else {
      handleChildrenClick(item);
    }
  };
  const handleChildrenClick = item => {
    if (item.route) {
      history.push(item.route);
    }
  };

  const logout = () => {
    localStorage.removeItem(config.token_name);
    localStorage.removeItem("staffDetails");
    history.push("/login");
  };
  return (
    <nav
      // style={{ marginTop: "63px" }}
      className="relative z-40 w-56 flex-shrink-0 sidebar-bg text-white overflow-y-auto"
    >
      {/* <img
				alt={""}
				className="fixed z-0 top-0 left-0 w-56 ml-5 object-contain"
				src={require("./../../assets/Odiopay-Pattern.svg")}
			/> */}
      <div className="relative z-10">
        <div className="w-full mx-auto mb-10">
          {/* <h1 className="ml-2 text-3xl mt-6 font-medium">Odiopay</h1> */}

          {/* <div className="ml-2 flex justify-between items-center mt-5 mb-6">
						<div>
							<span className="">Welcome home</span>
							<h6 className="text-sm font-bold">John Doe</h6>
						</div>
						<div className="flex items-center">
							<span className="relative">
								<span
									className="cursor-pointer text-xl font-extrabold block transform rotate-90 mr-1"
									onClick={() => setDrop(!drop)}
								>
									&rsaquo;
								</span>

								<ul
									className={
										(drop ? "drop-in" : "drop-out") +
										" drop shadow-xl absolute bg-white text-gray-1 font-normal  right-0 py-2 divide-y divide-gray-300"
									}
								>
									<li className="px-8 py-1 hover:text-black hover:font-bold">
										<Link to={"/settings"}>Settings</Link>
									</li>
									<li className="px-8 py-1 hover:text-black hover:font-bold">
										<button onClick={logout}>Logout</button>
									</li>
								</ul>
							</span>
							<img
								onClick={() => setDrop(!drop)}
								alt=""
								src=""
								className="cursor-pointer h-8 w-8 rounded-full bg-gray-200"
							/>
						</div>
					</div> */}
          <div className="flex mb-2 mt-10 ">
            <img
              alt={""}
              className="w-20 ml-8  object-contain"
              src={require("./../../assets/Logo-Sinle-colour.png")}
            />
          </div>

          <ul className="space-y-3 mt-10">
            {sidebar.sidebar_data.map((item, i) => (
              <li className="" key={"sidebar-item" + i}>
                <span
                  onClick={() => handleItemClick(item)}
                  className={
                    (item.name === activeItem || isActive(item)
                      ? "text-gray-700 cusWhi"
                      : "") +
                    " py-3 w-full px-3 flex items-center cursor-pointer"
                  }
                >
                  <img className="mr-5 h-5 w-5" src={item.icon} alt="" />
                  <span
                    className={
                      (isActive(item) ? "font-semibold" : "font-normal") +
                      " text-sm text-gray-400"
                    }
                  >
                    {item.name}
                  </span>
                  {!!item.children.length && (
                    <span
                      className={
                        (item.name === activeItem ? "rotate-90" : "rotate-0") +
                        " transform duration-500  transition-transform ml-auto text-lg font-semibold"
                      }
                    >
                      &rsaquo;
                    </span>
                  )}
                </span>
                {!!(item.children && item.children.length) && (
                  <ul
                    className={
                      (item.name === activeItem
                        ? "s-drop-in mt-3 mb-8"
                        : "s-drop-out") + " s-drop ml-10  "
                    }
                  >
                    {item.children.map((item_child, j) => (
                      <li
                        onClick={() => handleChildrenClick(item_child)}
                        className={
                          (history.location.pathname === item_child.route
                            ? "font-bold"
                            : "") +
                          (item_child.route ? "" : " opacity-25 ") +
                          " leading-tight text-xs py-2 cursor-pointer"
                        }
                        key={"item_child" + i + j}
                      >
                        {item_child.name}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          {/* <ul className="mt-32 text-right mr-6 space-y-6">
                        {sidebar.bottom_sidebar_data.map((item, i) => (
                            <li
                                onClick={() => handleChildrenClick(item)}
                                className={`${
                                    isActive(item) ? "font-bold" : "font-normal"
                                } ${
                                    item.route ? "" : "opacity-50"
                                } cursor-pointer`}
                                key={"bottom-side-item" + i}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ul> */}
        </div>
      </div>
    </nav>
  );
}
