import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { ExtractUserContext } from "../context/app_context";

const CanExecute = ({ permissions, children }) => {
	const [showComponent, setShowComponent] = useState(false);
	const { appState } = ExtractUserContext();
	const userPermission = appState.user.permissions.length
		? appState.user.permissions.map((each) => {
				return each.permit_name;
		  })
		: [];

	useEffect(() => {
		if (!permissions.length) {
			setShowComponent(true);
		} else {
			const res = permissions.some((allowedPermission) => {
				return userPermission.includes(allowedPermission);
			});

			setShowComponent(res);
		}
	}, [userPermission, permissions]);

	return <>{showComponent ? children : null}</>;
};

CanExecute.propTypes = {
	permissions: PropTypes.array,
	children: PropTypes.element.isRequired,
};

export default CanExecute;
