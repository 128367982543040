import React, { useEffect, useState } from "react";
import { LoaderOverlay } from "../../components/loader";
import { ExtractUserContext } from "../../context/app_context";
import { getACustomer } from "../../utils/api-request";
import { useToasts } from "react-toast-notifications";
import { useManageProfile } from "../../hooks.js/useManageProfile";

const ManageProfile = () => {
	const { handleChange, handleUpdate, formData, errors, loading } = useManageProfile();
	const show = Object.keys(formData).length > 0;

	// if (loading) {
	// 	return <LoaderOverlay />;
	// }

	return (
		<section className="flex mt-10 items-center justify-center">
			{loading && <LoaderOverlay />}
			{show && (
				<div className="bg-white shadow-md p-8">
					<h6 className="font-bold mb-10">Edit Profile Details</h6>
					<div>
						<form onSubmit={handleUpdate}>
							<div className="grid grid-cols-2 gap-3">
								<div>
									<label>First Name</label>
									<input
										type="text"
										onChange={handleChange}
										name="first_name"
										value={formData.first_name}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-2 text-black p-3 font-normal"
									/>
									{errors.first_name && (
										<span className="font-normal text-xs text-red-500">
											{errors.first_name}
										</span>
									)}
								</div>
								<div>
									<label>Last Name</label>
									<input
										type="text"
										name="last_name"
										onChange={handleChange}
										value={formData.last_name}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-2 text-black p-3 font-normal"
									/>
									{errors.last_name && (
										<span className="font-normal text-xs text-red-500">
											{errors.last_name}
										</span>
									)}
								</div>
								<div>
									<label>Email</label>
									<input
										type="text"
										value={formData.email}
										onChange={handleChange}
										name="email"
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-2 text-black p-3 font-normal"
									/>
									{errors.email && (
										<span className="font-normal text-xs text-red-500">
											{errors.email}
										</span>
									)}
								</div>
								<div>
									<label>Phone Number</label>
									<input
										type="text"
										name="phone"
										value={formData.phone}
										onChange={handleChange}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-2 text-black p-3 font-normal"
									/>{" "}
									{errors.phone && (
										<span className="font-normal text-xs text-red-500">
											{errors.phone}{" "}
										</span>
									)}
								</div>
								<div>
									<label>Username</label>
									<input
										type="text"
										name="username"
										value={formData.username}
										onChange={handleChange}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-2 text-black p-3 font-normal"
									/>{" "}
									{errors.username && (
										<span className="font-normal text-xs text-red-500">
											{errors.username}{" "}
										</span>
									)}
								</div>
							</div>
							<div className="flex mt-6 justify-center">
								<input
									style={{ borderRadius: "16px" }}
									className="cusBlue cursor-pointer px-8 py-2 text-white"
									type="submit"
									value="Update Details"
								/>
							</div>
						</form>
					</div>
				</div>
			)}
		</section>
	);
};

export default ManageProfile;
