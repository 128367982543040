import { useState } from "react";
import { validateRegisterFormData } from "../form-validation/register-validation";
import { register } from "../utils/api-request";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

export const useRegister = () => {
	const { addToast } = useToasts();
	const history = useHistory();

	const [others, setOthers] = useState({
		isSubmitting: false,
	});

	const [errors, setErrors] = useState({});

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		username: "",
		password: "",
		phone: "",
		agree: false,
	});

	const handleFormSubmit = async () => {
		setOthers({ isSubmitting: true });

		const { validationErrors, noOfError } = validateRegisterFormData(formData);

		setErrors(validationErrors);

		if (noOfError > 0) {
			setOthers({ isSubmitting: false });
			return;
		}
		const userData = { ...formData };
		delete userData.agree;

		try {
			const response = await register(userData);
			if (response.status === 200) {
				addToast("Your account has been successfully created", {
					appearance: "success",
					autoDismiss: true,
				});
				history.push("/login");
			}
		} catch (error) {
			setOthers({ isSubmitting: false });

			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response.status === 409) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			} else {
				addToast("Something went wrong", {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	const handleInputChange = (e) => {
		if (e.target.name === "agree") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	return {
		formData,
		others,
		handleInputChange,
		errors,
		handleFormSubmit,
	};
};
