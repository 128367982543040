import React from "react";

export function Card({ title, children }) {
	return (
		<div className="bg-white text-gray-1 shadow-md rounded p-5">
			{!!title && <h6 className="text-lg font-semibold mb-6">{title}</h6>}
			{children}
		</div>
	);
}
export function ErrorText({ error }) {
	return <span className="block text-red-500 text-center text-xs">{error}</span>;
}
