import React from "react";
import { Link } from "react-router-dom";

const Unauthorized = () => {
	return (
		<div
			style={{ minHeight: "70vh" }}
			className="mt-5 flex justify-center items-center"
		>
			<div>
				<p className="text-red-600 font-normal">
					You are not Authorized to view this page.
				</p>

				<div className="flex mt-5 justify-center">
					<Link to={"/dashboard"}>
						<span className="py-3 px-5 text-white rounded bg-green-500">
							Return Home
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Unauthorized;
