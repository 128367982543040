import React from "react";
import Chart from "react-apexcharts";

const RadialChart = ({ colorValue, amount }) => {
	const settings = {
		colors: [`${colorValue}`],
		labels: [amount],
		plotOptions: {
			radialBar: {
				hollow: {
					size: "50%",
				},
				track: {
					dropShadow: {
						enabled: true,
						top: 0,
						left: 0,
						blur: 3,
						opacity: 0.01,
					},
				},
				dataLabels: {
					show: true,
					name: {
						show: true,
						fontSize: "13px",
						offsetY: 5,
					},
					value: {
						show: false,
						fontSize: "14px",
						fontFamily: "Helvetica",
					},
				},
			},
		},
	};

	return (
		<div>
			<Chart options={settings} series={[70]} type="radialBar" width="200" height="240" />
		</div>
	);
};

export default RadialChart;
