import React from "react";

export function useSingleState(initialState) {
	const [data, setData] = React.useState(initialState);

	return {
		use: data,
		set: setData,
	};
}
