import React from "react";

export function LoaderOverlay() {
	return (
		<div className="z-50 flex items-center justify-center fixed top-0 left-0 bg-opacity-25 h-screen w-screen overflow-hidden bg-black">
			<span className="spinner-spin h-12 w-12" />
		</div>
	);
}

export function LoaderSpinner() {
	return <div className="spinner-spin h-12 w-12 mt-20" />;
}
