import { useState } from "react";

export function useApiCall() {
	const [error, setError] = useState({});
	const [valError, setValError] = useState({});
	const [loading, setLoading] = useState(false);

	async function callApi(apiFunction, successCallback, errorCallback) {
		try {
			setError({});
			setValError({});
			setLoading(true);
			const response = await apiFunction();
			// console.log(response);
			if (response.data) {
				// console.log(response);
				if (!!successCallback) {
					successCallback(response.data);
					console.log(successCallback, apiFunction);
					// console.log(response);
				}
			} else {
				successCallback("");
			}
		} catch (err) {
			if (err.response) {
				console.log(err.response.data);
				setValError(err.response.data || {});
				if (err.response.data.message) {
					setError(err.response.data.message);
					setLoading(true);
				}
				if (!!errorCallback) {
					console.log("error callback");
					errorCallback(err.response);
				}
			}
		} finally {
			setLoading(false);
		}
	}

	return { loading, error, valError, callApi };
}
