import React, { useContext, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { AppContext } from "../../context/app_context";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { handleSidebar, removeTokenFromStorage } from "../../utils/functions";

export function AppHeader() {
	const { appState, setUserObject } = useContext(AppContext);
	const [drop, setDrop] = useState(false);
	const history = useHistory();
	const logout = () => {
		removeTokenFromStorage();

		setUserObject(null);
		history.push("/login");
	};

	return (
		<div className="shadow-md sticky top-0 z-50 bg-white py-4 px-5 w- pr-12 flex items-center">
			<SwitchTransition>
				<CSSTransition
					key={appState.page_title}
					addEndListener={(node, done) =>
						node.addEventListener("transitionend", done, false)
					}
					classNames="fade"
				>
					<h2 className="text-xl font-semibold text-gray-1">{appState.page_title}</h2>
				</CSSTransition>
			</SwitchTransition>
			<div className="ml-auto" />
			<div className="ml-2 flex justify-between items-center ">
				<div>
					<span className="text-gray-900 font-bold">Welcome home</span>
					<h6 className="text-sm text-gray-500 text-right">John Doe</h6>
				</div>
				<div className="flex ml-3  items-center">
					<span
						onClick={() => setDrop(!drop)}
						// alt=""
						// src=""
						className="cursor-pointer h-8 w-8 rounded-full mr-3 bg-gray-200"
					></span>
					<span className="relative">
						<span
							className="cursor-pointer text-xl font-extrabold block transform rotate-90 mr-1"
							onClick={() => setDrop(!drop)}
						>
							&rsaquo;
						</span>

						<ul
							className={
								(drop ? "drop-in" : "drop-out") +
								" drop shadow-xl absolute bg-white text-gray-1 font-normal  right-0 py-2 divide-y divide-gray-300"
							}
						>
							<li className="px-8 py-1 hover:text-black ">
								<Link
									onClick={() => {
										setDrop(!drop);
									}}
									to={"/settings"}
								>
									Settings
								</Link>
							</li>
							{/* <li className="px-8 py-1 hover:text-black hover:font-bold">
								<button onClick={logout}>Logout</button>
							</li> */}
						</ul>
					</span>
				</div>
				<div className="flex items-center ml-8">
					<img
						className={"mr-6 cursor-pointer h-5 w-5"}
						src={require("./../../assets/sidebar-icons2/ICON - notificationstopbar.svg")}
						alt=""
					/>
					<img
						onClick={logout}
						className={"mr-2 cursor-pointer h-5 w-5"}
						src={require("./../../assets/sidebar-icons2/Group 2topbar.svg")}
						alt=""
					/>
				</div>
			</div>
			{/* <span className="font-semibold">John Doe</span>
            <Link to={"/settings"}>
                <img
                    className="mx-3 h-4"
                    src={require("./../../assets/settings-24px.svg")}
                    alt="settings"
                />
            </Link> */}
		</div>
	);
}
