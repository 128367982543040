import React, { useEffect, useRef } from "react";
import { useReducerState } from "../../hooks.js/useReducerState";
import { useSingleState } from "../../hooks.js/useSingleState";

const getInitials = (first, last) => {
	const init = first.slice(0, 1).toUpperCase();
	const init2 = last.slice(0, 1).toUpperCase();
	return `${init}${init2}`;
};

const RecentCustomer = ({ customers }) => {
	const customerData = useReducerState({ data: [] });
	const status = useSingleState("Ascending");
	const startRef = useRef(false);
	const storeRef = useRef(customers);

	useEffect(() => {
		const sorted = customers.sort((a, b) => {
			return a.first_name.localeCompare(b.first_name);
		});

		customerData.set({ data: sorted });
		startRef.current = true;
	}, []);

	useEffect(() => {
		if (customerData.use.data.length > 0 && startRef.current) {
			console.log("ran");
			if (status.use === "Ascending") {
				const newData = storeRef.current.sort((a, b) => {
					return a.first_name.localeCompare(b.first_name);
				});
				console.log(newData);

				customerData.set({ data: newData });
			} else {
				const newData = storeRef.current.sort((b, a) => {
					return a.first_name.localeCompare(b.first_name);
				});
				console.log(newData);
				customerData.set({ data: newData });
			}
		}
	}, [status.use]);

	return (
		<div>
			<div className="flex px-4 border-b-2 border-gray-200 pb-3 items-center justify-between">
				<p className="text-gray-800">Recent Customers</p>
				<div className="ml-2">
					<select
						onChange={(e) => {
							status.set(e.target.value);
						}}
						value={status.use}
						className="bg-transparent py-1 rounded-md focus:outline-none px-2 admin-blue-text"
						name="status"
					>
						<option value="Ascending">Ascending</option>
						<option value="Descending">Descending</option>
					</select>
				</div>
			</div>
			<div className="mt-6 px-4">
				{customerData.use.data.map((each) => {
					const { first_name, last_name } = each;
					return (
						<div key={Math.random() * 100000} className="mb-2 flex items-center">
							<span className="h-10 w-10 bg-purple-200 text-purple-700 flex items-center justify-center rounded-full">
								{getInitials(first_name, last_name)}
							</span>

							<div className="ml-8">
								<p className="text-gray-700">{`${first_name} ${last_name}`}</p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RecentCustomer;
