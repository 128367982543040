import React, { useEffect, useRef, useState } from "react";

export function InputForm({
	mb = false,
	stacked = false,
	label,
	textColor = false,
	value,
	name,
	setValue,
	required = false,
	type = "text",
	placeholder = "",
	editable = true,
	onBlur = () => {},
	error = "",
	round = false,
}) {
	return (
		<div className={stacked ? "w-full mb-5" : "flex items-center justify-between mb-3"}>
			{label && (
				<label
					className={
						(stacked ? "" : "w-3/12") +
						" mr-2 block mb-3" +
						" " +
						(textColor ? "force-black" : "")
					}
				>
					{label}
					{required && <span className="ml-1 text-red-500">*</span>}
				</label>
			)}
			<div className="flex-1">
				<input
					round={round}
					required={required}
					value={value}
					name={name}
					onChange={(e) =>
						type === "file"
							? setValue(e.currentTarget.files[0])
							: setValue(e.currentTarget.value)
					}
					placeholder={placeholder}
					className={
						(editable ? "bg-transparent" : "bg-gray-200") +
						" " +
						(round ? "rounded-lg" : "") +
						" w-full h-10 border p-3 border-gray-400 text-black" +
						" " +
						(mb ? "mt-3" : "")
					}
					type={type}
					readOnly={!editable}
					onBlur={onBlur}
				/>
				<span className="text-small text-red-500">{error}</span>
			</div>
		</div>
	);
}

export function SelectForm({
	stacked = false,
	label,
	name,
	value,
	disabled = false,
	setValue,
	required = false,
	getValue = (u) => u,
	getName = (u) => u,
	data = [],
	loading = false,
	error = "",
	bottomMargin = true,
	bordered = true,
}) {
	const select = useRef();
	return (
		<div
			className={
				stacked
					? "w-full mb-5"
					: `flex items-center justify-between ${bottomMargin ? "mb-3" : ""}`
			}
		>
			{label && (
				<label className={(stacked ? "" : "w-3/12") + " mr-2 mb-3 block"}>
					{label}
					{required && <span className="ml-1 text-red-500">*</span>}
				</label>
			)}
			<div className="flex-1">
				<div
					className={`flex ${
						bordered ? "border border-gray-400" : ""
					} items-center relative`}
				>
					<select
						disabled={disabled}
						ref={select}
						className="bg-transparent w-full pl-5 h-10 pr-5 text-black appearance-none"
						value={value}
						name={name}
						required={required}
						onChange={(e) => setValue(e.currentTarget.value)}
					>
						{data.map((d, i) => (
							<option key={Math.random() * 100000} value={getValue(d)}>
								{getName(d)}
							</option>
						))}
					</select>
					<span
						style={{ right: "10px" }}
						onClick={() => select.current.click()}
						className="block pr-1 absolute right-0"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill={disabled ? "rgba(0,0,0,.1)" : "black"}
							width="24px"
							height="24px"
						>
							<path d="M0 0h24v24H0z" fill="none" />
							<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
						</svg>
					</span>
				</div>
				<span className="text-small text-red-500">{error}</span>
			</div>

			{loading && (
				<div className="border h-10 px-1 flex items-center justify-center">
					<span className="h-5 spinner-spin w-5"></span>
				</div>
			)}
		</div>
	);
}

export function AutoCompleteForm({
	label,
	title = "",
	onSelect,
	shouldEmpty = false,
	getName,
	selected,
	searchFunction,
	data = [],
	loading = false,
	required = false,
	stacked = false,
}) {
	const [searchTerm, setSearchTerm] = useState("");
	const [inFocus, setInFocus] = useState(false);

	useEffect(() => {
		searchFunction(searchTerm);
	}, [searchTerm]);
	useEffect(() => {
		if (shouldEmpty) {
			setSearchTerm("");
		}
	}, [shouldEmpty]);
	const showAutoComplete = data.length > 0 && inFocus;

	return (
		<div
			className={
				stacked ? "w-full mb-5" : "flex font-medium items-center justify-between mb-3"
			}
		>
			{!!label && (
				<label className={(stacked ? "" : "w-3/12") + " mr-2"}>
					{label}
					{required && <span className="ml-1 text-red-500">*</span>}
				</label>
			)}
			<div className="relative flex-1">
				<div className=" flex items-center">
					<input
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.currentTarget.value)}
						onFocus={() => setInFocus(true)}
						onBlur={() => setTimeout(() => setInFocus(false), 1000)}
						className="px-3 w-full h-10 border "
						type="text"
						placeholder={"Select or search " + title}
						required={required}
					/>
					{loading && (
						<div className="h-10 flex items-center justify-center px-1  border">
							<span className="spinner-spin w-6 h-6" />
						</div>
					)}
				</div>
				<span style={{ fontSize: "0.5rem" }}>{selected}</span>
				{showAutoComplete && (
					<ul
						className="absolute bg-white w-full shadow-lg z-10 overflow-y-auto mb-16"
						style={{ maxHeight: "250px" }}
					>
						{data.map((u) => (
							<li
								onClick={() => {
									setSearchTerm(getName(u));
									setInFocus(false);
									onSelect(u);
								}}
								className="border-b py-2 px-3 hover:bg-blue-1 hover:text-white"
							>
								{getName(u)}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}

export function AutoSelfCompleteForm({
	label,
	onSelect,
	title = "",
	stacked = false,
	getName,
	selected,
	searchHook,
	searchFormat = "$$",
	required = false,
}) {
	const [searchTerm, setSearchTerm] = useState("");
	const [inFocus, setInFocus] = useState(false);
	const data = searchHook(searchFormat.replace("$$", searchTerm));

	const showAutoComplete = data.data.length > 0 && inFocus;
	return (
		<div
			className={
				stacked ? "w-full mb-5" : "flex font-medium items-center justify-between mb-3"
			}
		>
			{!!label && (
				<label className={(stacked ? "" : "w-3/12") + " mr-2"}>
					{label}
					{required && <span className="ml-1 text-red-500">*</span>}
				</label>
			)}
			<div className="relative flex-1">
				<div className=" flex items-center">
					<input
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.currentTarget.value)}
						onFocus={() => setInFocus(true)}
						onBlur={() => setTimeout(() => setInFocus(false), 1000)}
						className="px-3 w-full h-10 border "
						type="text"
						placeholder={"Select or search " + title}
						required={required}
					/>
					{data.loading && (
						<div className="h-10 flex items-center justify-center px-1  border">
							<span className="spinner-spin w-6 h-6" />
						</div>
					)}
				</div>
				<span style={{ fontSize: "0.5rem" }}>{selected}</span>
				{showAutoComplete && (
					<ul
						className="absolute bg-white z-10 w-full shadow-lg overflow-y-auto mb-16"
						style={{ maxHeight: "250px" }}
					>
						{data.data.map((u) => (
							<li
								onClick={() => {
									setSearchTerm(getName(u));
									setInFocus(false);
									onSelect(u);
								}}
								className="border-b py-2 px-3 hover:bg-blue-1 hover:text-white"
							>
								{getName(u)}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}
