import { lazy } from "react";
import { permissionsAllowed } from "../../../utils/permissions.js";

export default [
	{
		path: "/user/add",
		Component: lazy(() => import("./../../user/add_user.js")),
		permission: [permissionsAllowed.add_customer],
	},
	{
		path: "/user/view",
		Component: lazy(() => import("./../../user/view_users.js")),
		permission: [
			permissionsAllowed.view_customer,
			permissionsAllowed.add_customer,
		],
	},
];
