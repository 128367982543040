import { lazy } from "react";

export default [
	{
		path: "/repayment/make",
		Component: lazy(() => import("./../../repayment/make_repayment.js")),
		permission: [],
	},
	{
		path: "/repayment/approve-repayment",
		Component: lazy(() => import("./../../repayment/approve_repayment.js")),
		permission: [],
	},
	{
		path: "/repayment/all",
		Component: lazy(() => import("./../../repayment/view_all_repayments.js")),
		permission: [],
	},
];
