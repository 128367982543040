import { lazy } from "react";

export default [
	{
		path: "/disbursement/loan",
		Component: lazy(() => import("./../../disbursement/disburse-table.js")),
		permission: [],
	},
	{
		path: "/disbursement/history",
		Component: lazy(() => import("./../../disbursement/disburse_table2.js")),
		permission: [],
	},
];
