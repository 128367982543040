import React, { Suspense, useContext } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { LoaderOverlay } from "../components/loader";
import { AppCustomerHeader } from "./header/header";
import { AppContext } from "../context/app_context";
import { AppSidebar } from "./sidebar/sidebar";
import { appConfig } from "../utils/constants";
import { confirmIfStaffOrCustomer2 } from "../utils/functions";
import customerRoutes from "./routes/routes";
import Dashboard from "./dashboard";
import CustomerLoan from "./loan/loan";
import Reports from "./reports/reports";
import ManageProfile from "./manage-profile/manageProfile";
import Repayments from "./repayments/repayments";

function isLoggedIn() {
	return !!localStorage.getItem(appConfig.token_name2);
}
function isUser() {
	return !!JSON.parse(localStorage.getItem("staffDetails"));
}

const Customer = () => {
	const history = useHistory();
	const location = useLocation();
	const { appState } = useContext(AppContext);
	console.log(appState.user);
	if (!isLoggedIn()) {
		return <Redirect to={"/login"} />;
	}
	if (appState.user === null) {
		return <LoaderOverlay />;
	}
	if (isLoggedIn() && confirmIfStaffOrCustomer2(appState.user) === "staff") {
		return <Redirect to={"/dashboard"} />;
	}
	return (
		<div>
			<AppCustomerHeader />
			<div className="flex max-h-screen w-screen overflow-hidden">
				<AppSidebar />
				<div
					style={{ backgroundColor: "#F9F9FB" }}
					className="flex-1 flex flex-col h-screen overflow-x-hidden"
				>
					{/* <AppProvider> */}
					{/* <AppCustomerHeader /> */}
					<main
						style={{ marginTop: "70px" }}
						className="flex-1 flex-shrink-0  overflow-y-auto font-medium overflow-x-auto pb-16 px-5"
					>
						{history.location.pathname.includes("/customer/") && (
							<button
								onClick={() => history.goBack()}
								className="flex items-center mb-5 mt-3"
							>
								<span className="text-xl font-black">&lsaquo;</span>
								<span className="ml-1 text-xs font-semibold text-gray-500">
									Back
								</span>
							</button>
						)}
						<Suspense fallback={<LoaderOverlay />}>
							<SwitchTransition>
								<CSSTransition
									key={location.key}
									addEndListener={(node, done) =>
										node.addEventListener("transitionend", done, false)
									}
									classNames="fade"
								>
									<Switch location={location}>
										{/* <Route exact path="/">
											<Redirect to="/customer" />
										</Route> */}

										<Route exact path="/customer" component={Dashboard} />
										{/* <Route exact path="/customer/loan" component={CustomerLoan} />
										<Route exact path="/customer/reports" component={Reports} />
										<Route exact path="/customer/repayments" component={Repayments} />
										<Route exact path="/customer/manage-profile" component={ManageProfile} /> */}

										{customerRoutes.map(({ path, component }) => (
											<Route exact path={path} component={component} />
										))}
										<Route>
											<Redirect to="/customer" />
										</Route>
									</Switch>
								</CSSTransition>
							</SwitchTransition>
						</Suspense>
					</main>
					{/* </AppProvider> */}
				</div>
			</div>
		</div>
	);
};

export default Customer;
