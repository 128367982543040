import React from "react";

const Dots = ({ onClick }) => {
	return (
		<div onClick={onClick} className="ml-10 h-10 cursor-pointer px-2 py-3">
			<div
				style={{
					width: "5px",
					height: "5px",
					borderRadius: "50%",
				}}
				className="mb-1 bg-gray-400"
			></div>
			<div
				style={{
					width: "5px",
					height: "5px",
					borderRadius: "50%",
				}}
				className="mb-1 bg-gray-400"
			></div>
			<div
				style={{
					width: "5px",
					height: "5px",
					borderRadius: "50%",
				}}
				className="mb-1 bg-gray-400"
			></div>
		</div>
	);
};

export default Dots;
