import React from "react";
import RadialChart from "./radial-chart";

const DashCard = ({ colorValue, top, bottom, iconName, amount }) => {
	return (
		<div className="flex rounded-lg shadow-sm bg-white">
			<RadialChart amount={amount} colorValue={colorValue} />
			<div className="flex-1 pt-10">
				<h4 style={{ color: `${colorValue}` }} className="text-xl pr-2 text-center">
					{top} <br /> {bottom}
				</h4>
				<div className="flex justify-center items-center pl-3">
					{/* <div className="flex items-center">
						<h6 className="text-gray-600">10%</h6>
						<img
							className="h-3 block ml-1 w-3"
							src={require("../../assets/caret-down-solid.svg")}
							alt=""
						/>
					</div> */}
					<img
						className="h-16 block ml-2 w-16"
						src={require(`../../assets/${iconName}.svg`)}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};

export default DashCard;
