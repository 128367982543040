import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ExtractUserContext } from "../../../context/app_context";
import { useToasts } from "react-toast-notifications";
import { usePaystackPayment } from "react-paystack";
import { Modal, Overlay } from "../../../components/modal";
import { InputForm } from "../../../components/form_components";
import { makeRepaymentCustomer } from "../../../utils/api-request";

const MakeRepaymentModal = ({ dispatch, data }) => {
	// const [loading, setLoading] = useState(false);
	const [amt, setAmt] = useState("");
	const [id, setId] = useState(null);
	// console.log(data, data2);

	const { addToast } = useToasts();

	const closeModal = () => {
		dispatch({ type: "CHANGE", payload: { showRepaymentModal: false } });
	};

	const repayViaPaystack = async (e) => {
		e.preventDefault();
		dispatch({ type: "CHANGE", payload: { showFullPageLoader: true } });
		const userData = { amount: amt, loan_id: id };
		console.log(userData);
		try {
			const response = await makeRepaymentCustomer(userData);
			if (response.status.toString().startsWith("2")) {
				console.log(response.data.data);
				dispatch({
					type: "CHANGE",
					payload: {
						reference: { ...response.data.data, text: "Finalize Payment" },
						showFullPageLoader: false,
						showRepaymentModal: false,
						showContinueModal: true,
					},
				});
			}
		} catch (error) {
			dispatch({ type: "CHANGE", payload: { showFullPageLoader: false } });
			console.log(error);
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	useEffect(() => {
		setAmt(data.amount);
		setId(data.id);
	}, []);

	return (
		<Modal
			close={closeModal}
			style={{ backgroundColor: "#000000", opacity: ".95" }}
			className="w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center"
		>
			<div className="p-5 bg-white shadow-2xl rounded">
				<form onSubmit={repayViaPaystack} className="w-96 mt-5">
					<InputForm
						label="Amount to Pay"
						stacked={true}
						value={amt}
						setValue={setAmt}
						required={true}
						type="number"
						// error={null}
					/>

					<div className="flex justify-center mt-10">
						<span
							type="button"
							className="border cursor-pointer rounded px-5 mr-2 py-1"
							onClick={closeModal}
						>
							Cancel
						</span>
						<button
							className="bg-green-500 text-white rounded px-5 mr-2 py-1"
							type="submit"
						>
							Pay
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default MakeRepaymentModal;
