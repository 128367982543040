import { lazy } from "react";

export default [
	{
		path: "/customer/loan",
		component: lazy(() => import("../loan/loan.js")),
	},
	{
		path: "/customer/payment",
		component: lazy(() => import("../payment.js")),
	},
	{
		path: "/customer/view-profile",
		component: lazy(() => import("../manage-profile/view-profile.js")),
	},
	{
		path: "/customer/change-password",
		component: lazy(() => import("../../pages/auth/change-password")),
	},
	{
		path: "/customer/loan-calculator",
		component: lazy(() => import("../loan/loan-calculator.js")),
	},
	{
		path: "/customer/loan/create-loan",
		component: lazy(() => import("../loan/create-loan/create-loan.js")),
	},
	{
		path: "/customer/loan/create-borrower",
		component: lazy(() => import("../loan/create-borrower/create-borrower.js")),
	},
	{
		path: "/customer/loan/add-guarantor",
		component: lazy(() => import("../loan/add-guarantor/add-guarantor.js")),
	},
	{
		path: "/customer/reports",
		component: lazy(() => import("../reports/reports.js")),
	},
	{
		path: "/customer/repayments/:id",
		component: lazy(() => import("../repayments/view-loan-repayment.js")),
	},
	{
		path: "/customer/repayments",
		component: lazy(() => import("../repayments/repayments.js")),
	},

	{
		path: "/customer/manage-profile",
		component: lazy(() => import("../manage-profile/manageProfile.js")),
	},
	// {
	//     path: "/borrower/view",
	//     component: lazy(() => import("./../../borrower/view_borrower.js")),
	// },
	// {
	//     path: "/borrower/group/view",
	//     component: lazy(() =>
	//         import("./../../borrower/borrower_group/view_group.js")
	//     ),
	// },
	// {
	//     path: "/borrower/group/:action",
	//     component: lazy(() =>
	//         import("./../../borrower/borrower_group/add_group.js")
	//     ),
	// },
	// {
	//     path: "/borrower/send_sms",
	//     component: lazy(() => import("./../../borrower/invite/send_sms.js")),
	// },
	// {
	//     path: "/borrower/invite_customer",
	//     component: lazy(() =>
	//         import("./../../borrower/invite/invite_customer.js")
	//     ),
	// },
	// {
	//     path: "/borrower/send_email",
	//     component: lazy(() => import("./../../borrower/invite/send_email.js")),
	// },
];
