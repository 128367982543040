import { lazy } from "react";

export default [
	{
		path: "/settings",
		Component: lazy(() => import("./../../settings/settings.js")),
		permission: [],
	},
	{
		path: "/settings/branch",
		Component: lazy(() => import("./../../settings/branch/branches.js")),
		permission: [],
	},
	{
		path: "/settings/collateral_type",
		Component: lazy(() => import("./../../settings/collateral_type/view_collateral_type.js")),
		permission: [],
	},
	{
		path: "/settings/loan-type",
		Component: lazy(() => import("./../../settings/loan_type/loan_type.js")),
		permission: [],
	},
	{
		path: "/settings/loan-fees/view",
		Component: lazy(() => import("./../../settings/loan/ViewLoanFees.js")),
		permission: [],
	},
	{
		path: "/settings/loan-fees/add",
		Component: lazy(() => import("./../../settings/loan/AddLoanFees.js")),
		permission: [],
	},
	{
		path: "/settings/loan-fees/edit/:id",
		Component: lazy(() => import("./../../settings/loan/EditLoanFees.js")),
		permission: [],
	},
	// {
	// 	path: "/settings/loan-type",
	// 	Component: lazy(() => import("./../../settings/loan_type/loan_type.js")),
	// 	permission: [],
	// },
	{
		path: "/settings/savings-product",
		Component: lazy(() => import("./../../settings/savings_product/view_savings_product.js")),
		permission: [],
	},
	{
		path: "/settings/investment/investment-product",
		Component: lazy(() =>
			import("./../../settings/investments/investment_product/ViewInvestmentProduct.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/investment-product/add",
		Component: lazy(() =>
			import("./../../settings/investments/investment_product/AddInvestmentProduct.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/investment-product/edit/:id",
		Component: lazy(() =>
			import("./../../settings/investments/investment_product/EditInvestmentProduct.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/investment-fees",
		Component: lazy(() =>
			import("./../../settings/investments/investment_account_fees/ViewInvestmentFees.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/investment-fees/add",
		Component: lazy(() =>
			import("./../../settings/investments/investment_account_fees/AddInvestmentFees.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/investment-fees/edit/:id",
		Component: lazy(() =>
			import("./../../settings/investments/investment_account_fees/EditInvestmentFees.js")
		),
		permission: [],
	},
	{
		path: "/settings/investment/loan-investment-product",
		Component: lazy(() =>
			import(
				"./../../settings/investments/loan_investment_product/ViewLoanInvestmentProduct.js"
			)
		),
		permission: [],
	},
	{
		path: "/settings/investment/loan-investment-product/add",
		Component: lazy(() =>
			import(
				"./../../settings/investments/loan_investment_product/AddLoanInvestmentProduct.js"
			)
		),
		permission: [],
	},
	{
		path: "/settings/investment/loan-investment-product/edit/:id",
		Component: lazy(() =>
			import(
				"./../../settings/investments/loan_investment_product/EditLoanInvestmentProduct.js"
			)
		),
		permission: [],
	},
	// {
	// 	path: "/settings/investment/investment-product/edit/:id",
	// 	Component: lazy(() =>
	// 		import("./../../settings/investments/investment_product/EditInvestmentProduct.js")
	// 	),
	// 	permission: [],
	// },
	{
		path: "/settings/savings-product/add",
		Component: lazy(() => import("./../../settings/savings_product/add_savings_product.js")),
		permission: [],
	},
	{
		path: "/settings/savings-product/edit/:id",
		Component: lazy(() => import("./../../settings/savings_product/EditSavingsProduct.js")),
		permission: [],
	},
	{
		path: "/settings/savings-product-fee/add",
		Component: lazy(() => import("./../../settings/savings_product/add_savings_fee.js")),
		permission: [],
	},
	{
		path: "/settings/savings-product-fee",
		Component: lazy(() =>
			import("./../../settings/savings_product/view_savings_product_fees.js")
		),
		permission: [],
	},
	{
		path: "/settings/savings-product-fee/edit/:id",
		Component: lazy(() => import("./../../settings/savings_product/EditSavingsFee.js")),
		permission: [],
	},
	{
		path: "/settings/loan-type/add",
		Component: lazy(() => import("./../../settings/loan_type/add_loan_type.js")),
		permission: [],
	},
	{
		path: "/settings/country",
		Component: lazy(() => import("./../../settings/country/country.js")),
		permission: [],
	},
	{
		path: "/settings/loan_fees",
		Component: lazy(() => import("./../../settings/loan_fee/view_loan_fees.js")),
		permission: [],
	},
	{
		path: "/settings/roles",
		Component: lazy(() => import("./../../settings/role_management/view_roles.js")),
		permission: [],
	},
	{
		path: "/settings/roles/add",
		Component: lazy(() => import("./../../settings/role_management/add_roles.js")),
		permission: [],
	},
];
