import React, { useEffect } from "react";
import { LoaderSpinner } from "../../components/loader";
import { usePageName } from "../../context/app_context";
import { useFetch } from "../../hooks.js/useFetch";
import { useReducerState } from "../../hooks.js/useReducerState";
import { convertToCommaSeperatedNumber } from "../../utils/functions";
import DashCard from "./dash-card";
import PieChart from "./pie-chart";
import RecentCustomer from "./recent_customer";
import SunBurst from "./sunburst";
import TransLastChild from "./TransLastChild";

const formatTime = (time) => {
	console.log(time);
	if (time === undefined) {
		return;
	}
	if (time === null) {
		return "Not Available";
	}
	let newDate = time.split("T")[0].split("-");
	return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
};

export default function Dashboard() {
	usePageName("Dashboard");
	// const dashboardData = useReducerState({data: {}, loading: false})

	const { data, loading } = useFetch("/statistics/loan", {});

	// console.log(data);

	const showDash = Object.keys(data).length > 0 && !loading;
	const empty = data.loanProduct?.length === 0 && !loading;
	const showTransaction = data.latest_transactions?.length > 0;
	console.log(empty);

	console.log(showDash);

	return (
		<section className="mt-2">
			{!showDash && (
				<div style={{ height: "65%" }} className="flex justify-center items-center w-full">
					<LoaderSpinner />
				</div>
			)}

			{showDash && (
				<>
					<section className="flex justify-center px-3 mt-10">
						<div className="grid grid-cols-3 gap-6 w-full">
							<DashCard
								iconName="areamap"
								top={"Total Disbursed"}
								bottom={"Loans"}
								colorValue={"#79a1de"}
								amount={`₦${convertToCommaSeperatedNumber(data.total_disbursed)}`}
							/>
							<DashCard
								iconName="areamap2"
								top={"Total Number"}
								bottom={"of Users"}
								colorValue={"#75e76b"}
								amount={`${convertToCommaSeperatedNumber(
									data.total_verified_customers + data.total_unverified_customer
								)}`}
							/>
							<DashCard
								iconName="areamap3"
								top={"Principal"}
								bottom={"At Risk"}
								colorValue={"#79d2de"}
								amount={`₦${convertToCommaSeperatedNumber(
									data.total_principal_at_risk
								)}`}
							/>
						</div>
					</section>

					<section className="grid mt-8 grid-cols-3 px-3 gap-6">
						<div className="col-span-2 rounded-lg shadow-sm bg-white">
							<SunBurst data={data.big_pie_data} />
						</div>
						<div className="col-span-1 rounded-lg pt-16 shadow-sm bg-white py-4">
							<h6 style={{ color: "#79a1de" }} className="mb-2 text-center text-base">
								Loan Product Chart
							</h6>
							<div className="flex items-center justify-center">
								{empty && (
									<div
										style={{ height: "20vh" }}
										className="flex justify-center items-center"
									>
										<p className="text-gray-700 text-center">No Loan Product</p>
									</div>
								)}
								{!empty && <PieChart pieWidth={500} series={data.loanProduct} />}
							</div>
						</div>
					</section>
					<section className="grid mt-8 grid-cols-3 px-3 gap-6">
						<div className="col-span-2 rounded-lg shadow-sm pb-4 bg-white">
							<p className="mt-8 pl-4 text-gray-800">Latest Transaction</p>
							<div className="mt-4">
								{!showTransaction && <p className="text-center">No transaction</p>}
								{showTransaction && (
									<table class={`refactored-table-dash w-full `}>
										<thead>
											<tr class="text-gray-500  text-sm pl-5">
												<th
													style={{
														textAlign: "left",
														fontWeight: "normal",
														paddingLeft: "40px",
													}}
												>
													Date
												</th>

												<th
													style={{
														textAlign: "left",
														fontWeight: "normal",
													}}
												>
													Transaction Id
												</th>
												<th
													style={{
														textAlign: "left",
														fontWeight: "normal",
													}}
												>
													User Id
												</th>
												<th
													style={{
														textAlign: "left",
														fontWeight: "normal",
													}}
												>
													Value
												</th>
												{/* <th
													style={{
														textAlign: "left",
														fontWeight: "normal",
													}}
												>
													Status
												</th> */}
												<th
													style={{
														textAlign: "right",
														fontWeight: "normal",
														paddingRight: "20px",
													}}
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{data.latest_transactions.map((each, index) => {
												// const { branch_name, location } = each;
												return (
													<tr class="text-gray-600 pr-4 text-sm">
														<td
															style={{
																textAlign: "left",
																fontSize: "10px",
																fontWeight: "normal",
																paddingLeft: "40px",
															}}
														>
															{formatTime(each.createdAt)}
														</td>

														<td
															style={{
																textAlign: "left",
																fontSize: "10px",
																fontWeight: "normal",
															}}
														>
															{each.id}
														</td>
														<td
															style={{
																textAlign: "left",
																fontSize: "10px",
																fontWeight: "normal",
															}}
														>
															{`${each.loan_id.slice(0, 12)}...`}
														</td>
														<td
															style={{
																textAlign: "left",
																fontSize: "10px",
																fontWeight: "normal",
															}}
														>
															₦
															{convertToCommaSeperatedNumber(
																each.amount
															)}
														</td>
														{/* <td
                            style={{
                              textAlign: 'left',
                              fontsize: "10px",
                              fontWeight: 'normal',
                            }}
                          >
                            {each}
                          </td> */}
														<td
															style={{
																textAlign: "left",
																// fontsize: "10px",
																fontWeight: "normal",
															}}
														>
															<TransLastChild />
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								)}
							</div>
						</div>
						<div className="col-span-1 rounded-lg shadow-sm bg-white py-4">
							<RecentCustomer customers={data.recent_customers} />
						</div>
					</section>
				</>
			)}
		</section>
	);
}
